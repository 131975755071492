// Import React 
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import CSS File
import './tripCards.css';

const TripCards = (props) => {

    const tripElements = props.trips.map((trip, index) => (
        <div className={ (props.trips.length === 4) ? "col-12 col-md-6 mb-5" : "col-12  col-md-6 col-lg-4 mb-5"} key={index}>
            <div className="card h-100 shadow border-0">
                <img className="card-img-top" src={trip.imageURL} alt={trip.title} />
                <div className="card-body p-4">
                    <div className="badge bg-primary bg-gradient rounded-pill mb-2">{trip.age}</div>
                    <a className="text-decoration-none link-dark stretched-link" href={trip.link}><h5 className="card-title mb-3">{trip.title} | {trip.date}</h5></a>
                    <p className="card-text mb-0">{trip.text}</p>
                </div>
            </div>
        </div>
    ));

    return(
            <section className="py-0" id={props.id}>
                <div className={(props.isSummer ? "summer-section" : "winter-section")}>
                    <div className="container px-4 px-lg-5">
                        <div className="row gx-4 gx-lg-5">
                            <div className="col-md-10 col-lg-8 mx-auto text-center">
                                <i className="far fa-paper-plane fa-2x mb-2 text-white"><FontAwesomeIcon icon={props.category.icon} /></i>
                                <h2 className="text-white mb-5">{props.category.title}</h2>
                                <p className="text-white-75 mb-5">{props.category.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container px-5 my-5">
                    <div className="row gx-5">
                        { (props.trips.length === 0) ? 
                        <div className="col-12">
                            <div className="text-center">
                                <h4 className="mt-0">{props.category.title} - sind aktuell in der Planung und können demnächst gebucht werden!</h4>
                                <p className="text-dark-75 mb-4">Schau doch in ein paar Wochen noch einmal vorbei.</p>
                            </div>
                        </div> :
                        tripElements}
                    </div>
                </div>
            </section>
    )
}

export default TripCards;