// Import React 
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import CSS File
import './ourQuality.css';

const DEFAULT_NUMBER_ICONS = 4;

const OurQuality = (props) => {

    var randomQuality = randomItem(props.icons)

    function randomItem(items) { 
        if (items.length === 0) {return []}
        
        let allItems = items.slice();
        var displayedItems = [];

        for (var i = 0; i < DEFAULT_NUMBER_ICONS; i++) {
            var index = Math.floor(Math.random()*allItems.length);
            displayedItems.push(allItems[index]);
            allItems.splice(index, 1);
        }

        return displayedItems;
    }

    const services = randomQuality.map((service, index) => (
        <div className="col-lg-3 col-md-6 text-center" key={index}>
            <div className="mt-5 text-white">
                <div className="mb-2 text-secondary"><i className="fs-1"><FontAwesomeIcon icon={service.icon} /></i></div>
                <h3 className="h4 mb-2">{service.title}</h3>
                <p className="text-muted mb-0">{service.text}</p>
            </div>
        </div>
    ));

    return (
        <section className="page-section bg-primary" id="unsere-werte">
            <div className="container px-4 px-lg-5">
                <h2 className="text-center mt-0 text-white">Unsere Werte</h2>
                <hr className="divider divider-light" />
                <div className="row gx-4 gx-lg-5">
                    {services}
                </div>
            </div>
        </section>
    );
}

export default OurQuality
