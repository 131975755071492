// Import React 
import React from 'react';

import AreaLiving from './AreaLiving/AreaLiving';
import Program from './Program/Program';

const ShortFactCard = (props) => {

    return(
        <>
            {/* 100% short facts */}
            <div className="row">
                <div className="col-12">
                    <h4>{props.title}: {props.slogan}</h4>
                    <p>{props.shortFacts.join(' | ')}</p>
                    <p>{props.subslogan}</p>
                    <hr className="divider-trips" />
                </div>
            </div>
            <div className="row">
                <AreaLiving areaLiving={[props.facts.area, props.facts.living]} />
            </div>
            <div className="row">
                <Program program={props.facts.program} />
            </div>
            <div className="row">
                <p>{props.facts.evidence}</p>
            </div>
        </>
    )
}

export default ShortFactCard