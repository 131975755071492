import React from 'react'

import './faqCategory.css'

const FaqCategory = (props) => {

    const questionAnswer = props.questionAnswer.map((question, index) => (
        <div className="accordion-item" key={index}>
            <h3 className="accordion-header" id={`heading-${index}`}><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${index}`} aria-expanded="false" aria-controls={`collapse-${index}`}>{question.question}</button></h3>
            <div className="accordion-collapse collapse" id={`collapse-${index}`} aria-labelledby={`heading-${index}`} data-bs-parent={`#${props.category}`}>
                <div className="accordion-body">
                    <p>{question.answer}</p>
                </div>
            </div>
        </div>
    ));

    return (
        <>
            <h2 className="fw-bolder mb-3">{props.category}</h2>
            <div className="accordion mb-5" id={props.category}>
                {questionAnswer}
            </div> 
        </>
    )
}

export default FaqCategory
