import React, { useEffect } from 'react';

import { Navigation, Footer } from '../components/0_General/index.js';
import { Benefits, TripCategories, TripCards } from '../components/2_Trips/index.js';

import { tripsContent, winterTrips, summerTrips } from '../assets/wirsindferien';

function Freizeiten() {

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navigation isHome={false} />
      <TripCards id="sommerfreizeiten" isSummer={true} trips={summerTrips} category={tripsContent.summerCategory} />
      <TripCards id="winterfreizeiten" isSummer={false} trips={winterTrips} category={tripsContent.winterCategory} />
      <Benefits title="WIR!" tripsContent={tripsContent} />
      <Footer />
    </>
  );
}

export default Freizeiten;