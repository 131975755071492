import React, { useEffect } from 'react';

import { Navigation, Footer } from '../components/0_General/index.js';
import { FaqCategory, FaqContact } from '../components/5_Faq/index.js';
import { faq } from '../assets/wirsindferien.js';

function Faq() {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const faqCategories = faq.map((category, index) => (
        <FaqCategory category={category.category} questionAnswer={category.questionAnswer} key={index} />
    ));

    return (
        <>
            <Navigation isHome={false} />
            <section className="py-5">
                <div class="container px-5 my-5">
                    <div class="text-center mb-5">
                        <h1>FAQ</h1>
                        <hr className="divider divider-dark" />
                    </div>
                    <div class="row gx-5">
                        <div className="col-xl-8">
                            {faqCategories} 
                        </div>
                        <FaqContact />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Faq;