// Import React
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

// Import CSS Files
import './navigation.css';

const Navigation = (props) => {

    const [isShown, setIsShown] = useState(false)

    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top py-2" id={( props.isHome ? "navHome" : "navPage" )}>
            <div className="container px-4 px-lg-5">
                <Link to="/"><img className="navbar-logo" src="../img/logo/WirSindFerien/WSF_DE_Text_Colour.png" alt="WirSindFerien.de"/></Link>
                <button className="navbar-toggler navbar-toggler-right" onClick={() => setIsShown(!isShown)} type="button"><span className="navbar-toggler-icon"></span></button>
                <div className={isShown ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarResponsive">
                    <ul className="navbar-nav ms-auto my-2 my-lg-0">
                        <li className="nav-item"><Link className="nav-link" to="/freizeiten">Freizeiten</Link></li>
                        <li className="nav-item"><Link className="nav-link" to="/ueber-uns">Über uns</Link></li>
                        <li className="nav-item"><Link className="nav-link" to="/kontakt">Kontakt</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navigation