// Import React 
import React from 'react';

// Import CSS File
import './bookNow.css';

const BookNow = (props) => {
    return (
        <section className="page-section bg-secondary   ">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="text-dark mt-0">{props.homepageText.title}</h2>
                        <hr className="divider divider-dark" />
                        <p className="text-dark-75 mb-4">{props.homepageText.text}</p>
                        <a className="btn btn-primary btn-xl" href={props.homepageText.buttonLink}>{props.homepageText.buttonText}</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BookNow
