// Import React 
import React from 'react';

// Import CSS File
import './portfolioTrips.css';

const DEFAULT_NUMBER_TRIPS = 3;

const PortfolioFreizeiten = (props) => {

    var randomWinterTrips = randomItem(props.winterTrips);
    var randomSummerTrips = randomItem(props.summerTrips);

    function randomItem(items) { 
        if (items.length < 3) {return []}

        let allItems = items.slice();
        var displayedItems = [];

        for (var i = 0; i < DEFAULT_NUMBER_TRIPS; i++) {
            var index = Math.floor(Math.random()*allItems.length);
            displayedItems.push(allItems[index]);
            allItems.splice(index, 1);
        }

        return displayedItems;
    }

    

    const ImageBoxesWinter = randomWinterTrips.map((trip, index) => (
        <div className="col-lg-4 col-sm-6" key={`imageBox-${index}`}>
            <a className="portfolio-box" href={trip.link} title={`Freizeit: ${trip.name}`}>
                <img className="img-fluid" src={trip.imageURL} alt={`Freizeit: ${trip.name}`} />
                <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">{trip.category}</div>
                    <div className="project-name">{trip.title}</div>
                </div>
            </a>
        </div>
    ));

    const ImageBoxesSummer = randomSummerTrips.map((trip, index) => (
        <div className="col-lg-4 col-sm-6" key={`imageBox-${index}`}>
            <a className="portfolio-box" href={trip.link} title={`Freizeit: ${trip.category}`}>
                <img className="img-fluid" src={trip.imageURL} alt={`Freizeit: ${trip.category}`} />
                <div className="portfolio-box-caption">
                    <div className="project-category">{trip.category}</div>
                    <div className="project-name">{trip.title}</div>
                </div>
            </a>
        </div>
    ));

    return(
        <div id="portfolio">
            <div className="container-fluid p-0">
                <div className="row g-0">
                    {ImageBoxesSummer}
                    {ImageBoxesWinter}
                </div>
            </div>
        </div>
    )
}

export default PortfolioFreizeiten
