// Import React 
import React from 'react';

// Import CSS File
import './gallery.css';

const Gallery = (props) => {
    return (
        <section className="bg-primary">
            <div className="galleryRow px-1 py-4 mb-0 mw-xxl m-xxl-auto"> 
                <div className="col-3 column">
                    <img className="gallery-img" src={props.gallery[0].url} alt={props.gallery[0].alt}  />
                    <img className="gallery-img" src={props.gallery[1].url} alt={props.gallery[1].alt}  />
                </div>
                <div className="col-3 column">
                    <img className="gallery-img" src={props.gallery[2].url} alt={props.gallery[2].alt}  />
                    <img className="gallery-img" src={props.gallery[3].url} alt={props.gallery[3].alt}  />
                </div>
                <div className="col-3 column">
                    <img className="gallery-img" src={props.gallery[4].url} alt={props.gallery[4].alt}  />
                    <img className="gallery-img" src={props.gallery[5].url} alt={props.gallery[5].alt}  />
                </div>
                <div className="col-3 column">
                    <img className="gallery-img" src={props.gallery[6].url} alt={props.gallery[6].alt}  />
                    <img className="gallery-img" src={props.gallery[7].url} alt={props.gallery[7].alt}  />
                </div>
            </div>
        </section>
    )
}

export default Gallery
