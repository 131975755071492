// Import React 
import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet'


// Import CSS File
import './map.css';
import UpdateMarker from './UpdateMarker/UpdateMarker';

const Map = (props) => {

    return (
        <MapContainer center={props.map.latlng} zoom={14} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <UpdateMarker markerLatLng={props.map.latlng} markerText={props.map.popupText} />
        </MapContainer>
    )
}

export default Map
