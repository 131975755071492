import React, { useEffect } from 'react';

import { Navigation, Footer } from '../components/0_General/index.js';
import { Facts, Description, Gallery, Map } from '../components/3_Trip/index.js';

function Freizeit(props) {

  useEffect( () => {
      window.scrollTo(0, 0);
  }, [props])

  return (
    <>
      <Navigation isHome={false} />
      <Facts 
        title={props.trip.title} 
        slogan={props.trip.slogan} 
        shortFacts={props.trip.shortFacts}
        subslogan={props.trip.subslogan} 
        facts={props.trip.facts} 
        booking={props.trip.booking}
      />
      <Description title={props.trip.title} text={props.trip.longText}/>
      <Gallery gallery={props.trip.gallery} />
      <Map map={props.trip.map}/>
      <Footer />
    </>
  );
}

export default Freizeit;