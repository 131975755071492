// Import React and ReactDOM
import React from 'react';
import ReactDOM from 'react-dom';

// Import Styles
import './index.css';

// Import App Component
import App from './App';

// Mount React App
ReactDOM.render(
  React.createElement(App),
  document.getElementById('root'),
);
