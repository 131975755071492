// Import React 
import React from 'react';

// Import CSS File
import './benefits.css';

const Benefits = ({title, tripsContent}) => {

    const paragraphes = tripsContent.text.map((text, index) => (
        <div className="col-12 col-md-4 p-4" key={index}>
            <h3>{text.headline}</h3>
            <p className="text-justify">{text.text}</p>
        </div>
    ));

    const facts = tripsContent.shortFacts.map((fact, index) => (
        <div className="col-md-3 mb-3 mb-md-0" key={index}>
            <div className="card py-4 h-100">
                <div className="card-body text-center">
                    <h4 className="text-uppercase m-0 fs-1 text-primary">{fact.number}</h4>
                    <hr className="my-4 mx-auto" />
                    <div className="medium text-black-50">{fact.fact}</div>
                </div>
            </div>
        </div>
    ));

    return (
        <section className="page-section-sm bg-white">
            <div className="container px-4 px-lg-5">
                <div className="text-center">
                    <h2 className="mt-0">{title}</h2>
                    <hr className="divider divider-dark" />
                </div>
                <div className="row">
                    {paragraphes}
                </div>
            </div>
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5">
                    {facts}
                </div>
            </div>
            <div className="container px-4 px-lg-5 mt-4">
                <div className="row gx-4 gx-lg-5">
                    <div className="col-md-6 mb-3 mb-md-0">
                        <a className="btn btn-outline-primary w-100" href={tripsContent.summerCategory.link}>Zu unseren {tripsContent.summerCategory.title}!</a>
                    </div>
                    <div className="col-md-6 mb-3 mb-md-0">
                        <a className="btn btn-outline-primary w-100" href={tripsContent.winterCategory.link}>Zu unseren {tripsContent.winterCategory.title}!</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefits