import React from 'react'

import './BrowserLook.css'

const BrowserLook = (props) => {
    return (
        <section className="page-section bg-white">
            <div className="container px-4 px-lg-5">
                <div className="text-center">
                    <h1 className="mb-4">{props.errorType.title}</h1>
                    <div className="container_browser my-4">
                        <div className="row_browser">
                            <div className="column_browser left_browser">
                                <span className="dot_browser" style={{"background":"#ED594A"}}></span>
                                <span className="dot_browser" style={{"background":"#FDD800"}}></span>
                                <span className="dot_browser" style={{"background":"#5AC05A"}}></span>
                            </div>
                            {/* <div className="column middle">
                                <input type="text" value="">
                            </div> */}
                            <div className="column_browser right_browser">
                                <div style={{"float":"right"}}>
                                    <span className="bar_browser"></span>
                                    <span className="bar_browser"></span>
                                    <span className="bar_browser"></span>
                                </div>
                            </div>
                        </div>
                        <div className="content_browser">
                            <h2 className="mt-0">{props.errorType.type}</h2>
                            <hr className="divider divider-dark" />
                            <p>{props.errorType.text}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BrowserLook