import { faUserFriends, faHome, faHiking, faUmbrellaBeach, faMountain, faMapMarkedAlt, faHeart, faThumbsUp, faRunning, faSmileWink, faHandsHelping, faDoorOpen, faGem, faAward, faUsers, faSkiing, faBusAlt, faHands } from '@fortawesome/free-solid-svg-icons';

export const lorem = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"

// ------------------------------------------------- //
// --------------- Landing Page  ------------------- //
// ------------------------------------------------- //

export const slider = [
    {
        id: 1,
        isActive: true,
        className: "ueber-uns-slide",
        url: '/img/background/ueber-uns_2000x1500.jpg',
        headline: 'Das ist „Wir sind Ferien“',
        text: 'Wir sind Ferien ist eine Organisation für Kinder- und Jugendfreizeiten. Bewegung, Teamgeist und '+
        'Abenteuer stehen bei unseren Freizeiten und unseren ehrenamtlichen Betreuer:innen im Vordergrund.',
        buttonText: 'Mehr zu uns und unseren Werten!',
        link: '/ueber-uns'
    },
    {
        id: 2,
        isActive: false,
        className: "summer-slide",
        url: '/img/background/sommerfreizeit_2000x1500.jpg',
        headline: 'Sommerfreizeiten',
        text: 'Berge, Wald und Seen oder Meer und Strand? Wir bieten euch attraktive Reiseziele, an denen ihr euren Sommer so richtig genießen könnt.',
        buttonText: 'Zu den Sommerfreizeiten!',
        link: '/freizeiten#sommerfreizeiten'
    },
    {
        id: 3,
        isActive: false,
        className: "winter-slide",
        url: '/img/background/winterfreizeit_2000x1500.jpg',
        headline: 'Winterfreizeiten',
        text: 'Kein Winter ohne Ski oder Board? Das verstehen wir! Ob Anfänger oder fortgeschritten – hier findest du deinen perfekten Winterurlaub!',
        buttonText: 'Zu den Winterfreizeiten!',
        link: '/freizeiten#winterfreizeiten'
    },
]

export const homepageText = {
    title: "Nächsten Urlaub schon geplant?",
    text: "Du möchtest endlich mal Urlaub ohne deine Eltern mit Freunden machen oder neue Leute kennenlernen? Du möchtest raus und mal wieder richtig was erleben? Dann bist du bei uns genau richtig. Wir freuen uns, mit dir deinen Urlaub zu gestalten!",
    buttonText: "Zu allen Freizeiten!",
    buttonLink: "/freizeiten"
}

// ------------------------------------------------- //
// ----------------- Über uns  --------------------- //
// ------------------------------------------------- //

export const textUeberUns = [ 
    {
        text: "Wir sind Ferien ist eine Gruppe junger Menschen, die es sich zur Aufgabe gemacht hat, "+
        "Kindern und Jugendlichen unvergessliche Momente zu kreieren, die von Respekt, Spaß und tollen "+
        "Gruppenerlebnissen geprägt sind. "
    }, 
    {
        text: "Wir sind Ferien veranstaltet Kinder- und Jugendreisen, die ein abwechslungsreiches, "+
        "sportliches Programm bereithalten, sowie viel Spaß und tolle Erlebnisse mit Gleichaltrigen versprechen. "+
        "In Zukunft werden wir das Angebot an Freizeiten, Fortbildungen und Sportangeboten stetig ausweiten. " 
    }, 
    {
        text: "Hervorgegangen aus dem Freizeitbereich der Sportjugenden Rheinland-Pfalz, agieren wir seit 2020 als "+
        "selbstständige Einheit unter unserem Motto “Wir sind Ferien”. "
    }, 
    {
        text: "Gemeinsam mit unseren Partnern folgen wir auch weiterhin unserer Leidenschaft, die uns prägt und durch "+
        "langjährige Erfahrung auszeichnet. Unsere über 100 aktiven Betreuer sind nach dem Ausbildungskonzept des "+
        "Jugendleiters ausgebildet, haben zusätzliche Praxisausbildungen absolviert und durchlaufen regelmäßig "+
        "Fortbildungsveranstaltungen, um stets neue Impulse zu erhalten und unsere hohen Qualitätsansprüche zu repräsentieren."
    }
]

export const unsereWerte = ([
    {
        title: "Abenteuer & besondere Erlebnisse", 
        text: "",
        icon: faMapMarkedAlt 
    },
    {
        title: "Teamgeist",
        text: "",
        icon: faUserFriends 
    },
    {
        title: "Wertschätzung",
        text: "",
        icon: faHeart 
    },
    {
        title: "Fairness",
        text: "",
        icon: faThumbsUp 
    },
    {
        title: "Vielfalt",
        text: "",
        icon: faHands
    },
    {
        title: "Sport & Spiel",
        text: "",
        icon: faRunning 
    },
    {
        title: "Spaß",
        text: "",
        icon: faSmileWink 
    },
    {
        title: "Ehrenamt",
        text: "",
        icon: faHandsHelping 
    },
    {
        title: "Offenheit",
        text: "",
        icon: faDoorOpen 
    },
    {
        title: "Qualität",
        text: "",
        icon: faGem 
    },
    {
        title: "Respekt",
        text: "",
        icon: faAward 
    },
    {
        title: "Gruppenerlebnisse",
        text: "",
        icon: faUsers 
    }
]);

export const nahegold = {
    title: "Reisebüro Nahegold Omnibusbetrieb",
    text: "Nahegold ist ein langjährig erfahrenes Traditionsunternehmen in Rheinhessen rund um Busreisen & Personenbeförderungen. "+
    "Zur Busflotte zählen moderne und komfortable Reisebusse verschiedener Größen, die den Weg in die Ferien zu einem "+
    "Erlebnis machen. Vor Allem Freizeitgruppen finden in den geräumigen Fahrzeugen viel Platz für Gepäck & Sportgeräte. "+
    "Nahegold ist der Veranstalter unserer Freizeiten und für die gesamte Abwicklung zuständig.",
    imageURL: "/img/logo/Nahegold/Nahegold.png",
    buttonText: "Erfahre mehr über Nahegold!",
    link: "https://www.nahegold.de/"
  }

 export const skiverband = {
    title: "Skiverband Rheinhessen",
    text: "Der Skiverband Rheinhessen e.V. (SVRhh) ist der Fachverband für Wintersport in Rheinhessen. Rheinhessen "+
    "umfasst den Bereich zwischen Bingen, Bad-Kreuznach, Mainz, Alzey und Worms. Die Aus- und Fortbildung von "+
    "DSV-lizensierten Übungsleitern (Grundstufe & Instructor) für die Vereine sowie die Förderung von Kindern und "+
    "Jugendlichen im alpinen Rennsport sind die Hauptaufgaben des SVRhh. Ebenso gehören die Bereiche Nordischer Skisport "+
    "und Tourenwesen dazu.",
    imageURL: "/img/logo/Skiverband/logo_skiverband.png",
    buttonText: "Erfahre mehr über den Skiverband Rheinhessen!",
    link: "https://www.skiverband-rheinhessen.de/"
  }

// ------------------------------------------------- //
// ---------------- Freizeiten  -------------------- //
// ------------------------------------------------- //

export const tripsContent = {
    text: [
        {
            headline: "Freizeitstätten",

            text: "Unsere Freizeitstätten bieten Kindern und Jugendlichen zahlreiche Möglichkeiten für Sport, Bewegung und Entspannung, um auf den Freizeiten "+
            "eine unvergessliche Zeit zu erleben. Wir wählen die Unterkünfte sorgfältig aus. Zu den meisten Freizeitstätten besteht eine langjährige "+
            "Verbundenheit und zugleich kommen auch stetig neue Reiseziele hinzu. ",
        },
        {
            headline: "Betreuer:innen",
            text: "Unsere Betreuer:innen haben eine umfassende theoretische und praktische Ausbildung abgeschlossen. Neben pädagogischen Inhalten liegen "+
            "uns hierbei der wertschätzende Umgang und das Teambuilding sehr am Herzen. Wir sind stolz darauf, jedes Jahr neue Betreuer:innen auszubilden "+
            "und in unsere Betreuergemeinschaft aufzunehmen.",
        },
        {
            headline: "Erfahrungen",
            text: "Wir können bereits auf eine langjährige Erfahrung mit zahlreichen Kinder- und Jugendreisen zurückblicken. Wir entwickeln uns ständig "+
            "weiter und bleiben dank unserem jährlichen Zuwachs an Betreuer:innen stets am Puls der Zeit. Unsere Erfahrungen geben wir stetig weiter und "+
            "profitieren von den Kompetenzen jedes einzelnen Teammitglieds.",
        }
    ],
    summerCategory: {
        title: "Sommerfreizeiten",
        text: "Berge, Wald und Seen oder Meer und Strand? Wir bieten euch attraktive Reiseziele, an denen ihr euren Sommer so richtig genießen könnt.",
        icon: faUmbrellaBeach,
        link: "#sommerfreizeiten"
    },
    winterCategory: {
        title: "Winterfreizeiten",
        text: "Kein Winter ohne Ski oder Board? Das verstehen wir! Ob Anfänger oder Fortgeschrittene – hier findest du deinen perfekten Winterurlaub!",
        icon: faMountain,
        link: "#winterfreizeiten"
    },
    shortFacts: [
        {
            icon: faBusAlt,
            number: "4",
            fact: "Freizeiten für Kinder und Jugendliche von 10-15 Jahren"
        },
        {
            icon: faSkiing,
            number: "> 100",
            fact: "Betreuer:innen"
        },
        {
            icon: faSkiing,
            number: "0",
            fact: "Die Winterfreizeiten befinden sich derzeit noch in Planung"
        },
        {
            icon: faSkiing,
            number: "> 50",
            fact: "Jahre Erfahrung"
        }
    ]
}

export const summerTrips = [
    {
        id: 1,
        title: "Schubybeach in Dörphof",
        category: "Sommerfreizeit",
        age: "12 bis 15 Jahre",
        date: "23.07. - 31.07.2022",
        country: "Deutschland",
        imageURL: "img/trips/thumbnail/schubybeach_800x460.png",
        text: "Du willst Sand, Strand und Meer? Bei unserer Freizeit in Schleswig-Holstein an der Ostsee dreht sich alles "+
        "um den Wassersport. Windsurfen, Stand-Up Paddling, Sit-On-Top Kajaks oder Bananenboot – "+
        "hier ist für jeden was dabei!",
        link: "/freizeiten/schubybeach",
    },
    {
        id: 2,
        title: "Heino",
        category: "Sommerfreizeit",
        age: "10 bis 13 Jahre",
        date: "31.07. - 09.08.2022",
        country: "Niederlande",
        imageURL: "img/trips/thumbnail/heino_800x460.png",
        text: "Neben Poffertjes und Gouda hat Holland noch einiges mehr zu bieten. Im Sommercamp Heino warten verschiedene "+
        "Outdoor-Aktivitäten wie der Hochseilgarten und Bogenschießen, Kino-, Disco- und Lagerfeuerabende, ein Badesee und "+
        "vieles mehr auf euch!",
        link: "/freizeiten/heino",
    },
    {
        id: 3,
        title: "Obervellach",
        category: "Sommerfreizeit",
        age: "13 bis 15 Jahre",
        date: "15.08. - 26.08.2022",
        country: "Österreich",
        imageURL: "img/trips/thumbnail/obervellach_800x460.png",
        text: "Ob Canjoning oder Rafting - probiere neue Sportarten aus und erlebe den Nervenkitzel im Wildwasser! Komm mit "+
        "uns auf ein actionreiches Abenteuer im Aktiv-Sport-Erlebnis-Camp inmitten der Berge Österreichs!",
        link: "/freizeiten/obervellach",
    },
    {
        id: 4,
        title: "Flachau",
        category: "Sommerfreizeit",
        age: "12 bis 14 Jahre",
        date: "12.08. - 23.08.2022",
        country: "Österreich",
        imageURL: "img/trips/thumbnail/flachau_800x460.png",
        text: "Du suchst Action und Spaß in der wundervollen Bergwelt Österreichs? Dann bist du auf unserer Freizeit "+
        "in Flachau genau richtig! Es warten Seen und Berggipfel sowie eine Trampolinanlage und Turnhallen direkt vor Ort auf dich.",
        link: "/freizeiten/flachau",
    }
]


export const winterTrips = []

// ------------------------------------------------- //
// ------------- Sommerfreizeiten ------------------ //
// ------------------------------------------------- //

export const schubybeach = {
    title: "Schubybeach in Dörphof",
    slogan: "Sommerlicher Schuby Surf-Spaß",
    shortFacts: ["Windsurfen", "Meer", "Strand", "Ostsee", "Lagerfeuer"],
    subslogan: "Ein Surf-Erlebnis der Extraklasse wartet auf der Halbinsel Schwansen auf euch. Eine fantastische "+
    "Freizeit, die unzählige Freizeitangebote bietet. Egal ob Bouldern, Bolzen, ein entspannter Lagerfeuerabend oder "+
    "ein Ausflug ins nahegelegene Ostseebad Damp. Bei der Auswahl eurer Aktivitäten sind euch keine Grenzen gesetzt. "+
    "Ihr seht, hier ist jede Menge Spaß garantiert.",
    facts: {
        area: {
            headline: "Vor Ort",
            icon: faMapMarkedAlt,
            list: ["überdachte Boulderwand", "moderner Niederseilgarten mit Top-Rope Elementen und diversen Teambuilding-Modulen", "Bolzplatz mit Fußballtoren und weiteren Ballspielmöglichkeiten",
                "Tischtennisplatten", "Spielezelt mit Billard, Dart, Kicker und vielen anderen Spielen",
                "Outdoorspiele-Portfolio für Strand oder Wiese zum Ausleihen (Tamburello, Speedminton, Hockey, Frisbee-Golf)",
                "Grill- und Lagerfeuerplatz"]
        },
        living: {
            headline: "Unterkunft",
            icon: faHome,
            text: "Untergebracht sind wir in Holzbungalows, die sich im weitläufigen parkähnlichen Gelände befinden. Die Holzbungalows sind für bis zu 8 Personen "+
            "ausgelegt und mit eigener Toilette, teilweise auch eigener Dusche ausgestattet. Weitere Gemeinschaftssanitäranlagen sind im Haupthaus vorhanden, wo sich "
            +"auch die Mensa befindet. Die Vollverpflegung beläuft sich auf ein Frühstück, ein Lunchpaket zum Mittag und eine warme Mahlzeit am Abend."
        },
        program: {
            headline: "Programm",
            icon: faHiking,
            text: "Das absolute Highlight unserer Freizeit ist der Surfkurs unter fachkundiger Anleitung. Der inbegriffene 9-stündige Surfkurs ist samt aller Materialien "+
                "inklusive und ist sowohl für Anfänger als auch für Fortgeschrittene geeignet. Für die ganz Begeisterten unter euch ist es gegen eine Gebühr "+
                "von 39€ möglich, einen VDWS Grundschein im Windsurfen zu absolvieren. \n Gekrönt wird unsere Wind-Wasser-Sport freie Zeit "+
                "von einer Sea- und Beach-Olympiade. Zusätzlich werden wir die vielen Sportangebote vor Ort nutzen und sicherlich auch das "+
                "nahegelegene Ostseebad Damp erkunden. Weiterer Wassersport wie SUP(Stand Up Paddeling) Boards, Sit-On-Top-Kajaks und Tretboote "+
                "können vergünstigt ausgeliehen werden (gegen Gebühr).",
            list: []
        },
        evidence: "Unsere angebotenen Reisen sind standardmäßig für Personen mit eingeschränkter Mobilität nicht geeignet. Gerne möchten wir "+
                 "aber Personen mit eingeschränkter Mobilität die Teilnahme ermöglichen. Bitte deine Eltern, bei uns im Büro anzurufen, so "+
                 "dass wir das gemeinsam besprechen können.",
    },
    booking: {
        benefits: [
            {
                headline: "Termin",
                fact: "23.07./24.07.(Nachtfahrt) - 31.07.2022",
            },
            {
                headline: "Alter",
                fact: "12 bis 15 Jahre",
            },
            {
                headline: "Preis",
                fact: "575€",
            },
            {
                headline: "Teilnehmerzahl",
                fact: "mind. 35",
            }
        ],
        shortFacts:["Busreise ab/an Koblenz, Mainz, Raststätte Dannstadt", 
            "Unterkunft in Holzbungalows (bis zu 8 Personen) mit WC, Waschbecken & teilweise Dusche, Sanitäranalgen im Haupthaus",
            "Vollverpflegung","9-stündiger Surfkurs inkl. Material", "(Sport-) Programm & Ausflüge (wie beschrieben)",
            "Surfkurs in unterschiedlichen Leistungsgruppen "],
        link: "https://eveeno.com/545463930"
    },
    longText: "",
    gallery: [
        {
            url: "/img/trips/summer/schubybeach/1_schubybeach_500x375.png",
            alt: "Meer"
        },
        {
            url: "/img/trips/summer/schubybeach/2_schubybeach_500x670.png",
            alt: "See"
        },
        {
            url: "/img/trips/summer/schubybeach/3_schubybeach_500x670.png",
            alt: "Wind"
        },
        {
            url: "/img/trips/summer/schubybeach/4_schubybeach_500x375.png",
            alt: "Windsurfen"
        },
        {
            url: "/img/trips/summer/schubybeach/5_schubybeach_500x375.png",
            alt: "Surfkurs"
        },
        {
            url: "/img/trips/summer/schubybeach/6_schubybeach_500x670.png",
            alt: "Schafe"
        },
        {
            url: "/img/trips/summer/schubybeach/7_schubybeach_500x670.png",
            alt: "Strand"
        },
        {
            url: "/img/trips/summer/schubybeach/8_schubybeach_500x375.png",
            alt: "Sand"
        }
    ],
    map: {
        popupText: "Schubybeach ...",
        latlng: [54.59668721740213, 10.015925127443248]
    }
}

export const heino = {
    title: "Heino",
    slogan: "Herrlich, himmlisch, Heino-Sommercamp",
    subslogan:"Ein „fun-tastisches“ Sommererlebnis erwartet euch im „Summercamp Heino“. "+
    "Ein außergewöhnliches Feriencamp, das unzählige Freizeitmöglichkeiten bietet. Beachparty, "+
    "sportliche Turniere, Lagerfeuer mit Stockbrotessen, ein Ausflug in die Stadt Zwolle und vieles "+
    "mehr sorgen für eine ereignisreiche Freizeit. Die meisten Programmpunkte finden sich direkt am Haus. "+
    "Ihr seht, hier ist jede Menge Spaß garantiert.",
    shortFacts: ["Natur", "Bogenschießen", "Tiere", "Spaß"],
    facts: {
            area: {
                headline: "Vor Ort",
                icon: faMapMarkedAlt,
                list: ["Badesee mit Kanus, Flößen und Booten", "Hochseilgarten mit Kletterturm", 
                    "kleines Hallenbad", "Beachvolleyballplatz", "Großturnhalle", "Fußballlplatz", 
                    "Freilichttheater mit Feuerplatz", "Kino & Disco", "Tiere füttern auf der Tierwiese", "Schwarzlicht-Minigolf"
                ]
            },
            living: {
                headline: "Unsere Unterkunft",
                icon: faHome,
                text: "Der Ort Heino liegt in der Nähe von Zwolle in den Niederlanden. Das Camp ist mit seinen 16 Hektar das "+
                "größte Kinderferiendorf der Niederlande. \n Untergebracht sind wir in einem eigenen Haus mit 6 bis 8 "+
                "Bettzimmern sowie Duschen und WC auf der Etage. Zusätzlich steht uns dort ein kleiner Aufenthaltsraum "+
                "zur Verfügung. Die Vollverpflegung besteht aus einem Frühstück, kleinen Snacks am Mittag und einem warmen "+
                "Abendessen im Restaurant. Zu allen Mahlzeiten gibt es immer Tee und Wasser."
            },
            program: {
                headline: "Programm",
                icon: faHiking,
                text: "Jeden Tag etwas Neues erleben! Bei den vielseitigen Möglichkeiten, die das Feriendorf bietet, ist das "+
                "kein Problem. Ob Wasserspaß am Badesee, Minigolf oder Klettern – für jeden ist etwas dabei. An den Abenden "+
                "erwartet euch abwechslungsreiches Programm wie Gameshows, Lagerfeuer oder Nachtwanderungen.",
                list: []
            },
            evidence: "Unsere angebotenen Reisen sind standardmäßig für Personen mit eingeschränkter Mobilität nicht geeignet. Gerne möchten wir "+
            "aber Personen mit eingeschränkter Mobilität die Teilnahme ermöglichen. Bitte deine Eltern, bei uns im Büro anzurufen, so "+
            "dass wir das gemeinsam besprechen können.",
        },
    booking: {
        benefits: [
            {
                headline: "Termin",
                fact: "31.07. - 09.08.2022",
            },
            {
                headline: "Alter",
                fact: "10 bis 13 Jahre",
            },
            {
                headline: "Preis",
                fact: "445€",
            },
            {
                headline: "Teilnehmerzahl",
                fact: "mind. 35",
            }
        ],
        shortFacts:["Busreise ab/an Koblenz, Mainz, Raststätte Dannstadt", "Unterkunft in einfachen Mehrbettzimmern (6-8 Personen)",
        "Etagenduschen & WC", "Vollverpflegung (Küchendienst hilft bei der Vor- und Nachbereitung)",
        "(Sport-)Programm & Ausflüge (wie beschrieben)"],
        link: "https://eveeno.com/329167037"
    },

    longText: "",
    gallery: [
        {
            url: "/img/trips/summer/heino/1_heino_500x375.png",
            alt: "Tierpark"
        },
        {
            url: "/img/trips/summer/heino/2_heino_500x670.png",
            alt: "See"
        },
        {
            url: "/img/trips/summer/heino/3_heino_500x670.png",
            alt: "kein Bild"
        },
        {
            url: "/img/trips/summer/heino/4_heino_500x375.png",
            alt: "Hochseilgarten"
        },
        {
            url: "/img/trips/summer/heino/5_heino_500x375.png",
            alt: "Reiten"
        },
        {
            url: "/img/trips/summer/heino/6_heino_500x670.png",
            alt: "kein Bild"
        },
        {
            url: "/img/trips/summer/heino/7_heino_500x670.png",
            alt: "kein Bild"
        },
        {
            url: "/img/trips/summer/heino/8_heino_500x375.png",
            alt: "Bogenschießen"
        }
    ],
    map: {
        popupText: "Heino ...",
        latlng: [52.42428444584271, 6.221677427365225]
    }
}

export const obervellach = {
    title: "Obervellach",
    slogan: "Abenteuer-Action-ohne-Grenzen im Herzen der Alpen",
    subslogan:"Adrenalin-Kicks, Wassersport und ein einzigartiges Aktivprogramm mit jeder Menge Abenteuer "+
    "bietet dir die Freizeit in Obervellach. Umgeben von Wiesen, Wald und Bergen liegt unsere Freizeitstätte "+
    "direkt am Fluss Möll im schönen Mölltal in Österreich.",
    shortFacts: ["Wandern", "Alpen", "Österreich", "Action","Canyoning","Klettern","Natur"],
    facts: {
            area: {
                headline: "Vor Ort",
                icon: faMapMarkedAlt,
                text: "Wenn ihr von diesem vielseitigen Programm noch immer nicht genug habt, können wir (teilweise gegen Gebühr) "+
                "Ausflüge unternehmen, wie zum Beispiel eine Hüttentour mit Übernachtung, eine abenteuerliche Wanderung "+
                "durch eine gigantische Schlucht oder ein Besuch im Freibad mit 60m langer Rutsche.",
                list: ["camp-eigene Wasserwelt mit Kanus", "Baden und Chillen an der Möll", "Fußball und Basketball","Tischtennis", "Ultimate Frisbee"]
            },
            living: {
                headline: "Unsere Unterkunft",
                icon: faHome,
                text: "Die Unterkunft erfolgt in kleinen urgemütlichen Holzblockhütten mit bis zu 8 Personen. Duschen und WC befinden "+
                "sich in separaten Gebäuden. Die Verpflegung aus der Camp-Küche besteht aus Frühstück, Mittagessen und warmen Abendessen. "+
                "So könnt ihr gestärkt die Action genießen."
            },
            program: {
                headline: "Programm",
                icon: faHiking,
                text: "Hier gibt es ein grandioses Outdoor-Programm für euch! "+
                "Alle genannten Angebote sind auch für Anfänger geeignet!\n ",
                list: [
                    "Raftingtour: \nJede Menge Spaß mit Wasserschlacht und Schlauchbootkämpfen sowie der Sprung von "+
                    "einem 6 Meter hohen Felsen ins Wildwasser erwarten euch bei einer unvergesslichen Raftingtour auf der Möll.",

                    "Canyoning: \nWer noch weitere aufregende Sprünge wagen möchte, hat die einmalige Gelegenheit beim "+
                    "Canyoning. Ihr klettert über Steine und Böschungen, rutscht und springt von Felsvorsprüngen in natürliche "+
                    "Becken und überwindet Wasserfälle in Höhlen.",

                    "Kletterwand: \n Nach einer Klettereinführung und einem ersten Kletterkurs am Camp-eigenen "+
                    "Kletterturm, geht es von leicht (3. Grad) bis hart (8. Grad mit Überhang & Vorstieg) an den echten Fels. "+
                    "Hier kannst du deine Fähigkeiten unter Beweis stellen und dir deine eigene Herausforderung suchen.",

                    "Hochseilgarten: \n Im Hochseilgarten des Camps werdet ihr zusätzlich noch ein wenig Nervenkitzel erleben.",

                    "Motorikparcours: \n Teste dich im neugebauten Motorikpark. Es gibt über 100 Möglichkeiten die Hindernisse "+
                    "zu überwinden. Allein oder im Team - hier sind Geist, Konzentration und Körpergefühl gefragt."
                ]
            },
            evidence: "Unsere angebotenen Reisen sind standardmäßig für Personen mit eingeschränkter Mobilität nicht geeignet. Gerne möchten wir "+
            "aber Personen mit eingeschränkter Mobilität die Teilnahme ermöglichen. Bitte deine Eltern, bei uns im Büro anzurufen, so "+
            "dass wir das gemeinsam besprechen können.",
    },
    booking: {
        benefits: [
            {
                headline: "Termin",
                fact: "15.08./16.08.(Nachtfahrt) - 26.08.2022",
            },
            {
                headline: "Alter",
                fact: "13 bis 15 Jahre",
            },
            {
                headline: "Preis",
                fact: "655€",
            },
            {
                headline: "Teilnehmerzahl",
                fact: "mind. 35",
            }
        ],
        shortFacts: [	"Busreise ab/an Koblenz, Mainz", "Unterkunft in Mehrpersonenhütten (8 Personen)",
                 "Zentrale Sanitärgebäude (Duschen & WC)", "Vollverpflegung", "(Sport-) Programm & Ausflüge (wie beschrieben)",],
        link: "https://eveeno.com/115861495",
    },
    longText: "",
    gallery: [
        {
            url: "/img/trips/summer/obervellach/1_obervellach_500x375.png",
            alt: "Hüttendorf"
        },
        {
            url: "/img/trips/summer/obervellach/2_obervellach_500x670.png",
            alt: "Klettern"
        },
        {
            url: "/img/trips/summer/obervellach/3_obervellach_500x670.png",
            alt: "Hochseilgarten"
        },
        {
            url: "/img/trips/summer/obervellach/4_obervellach_500x375.png",
            alt: "Sonnenaufgang"
        },
        {
            url: "/img/trips/summer/obervellach/5_obervellach_500x375.png",
            alt: "Sportplatz"
        },
        {
            url: "/img/trips/summer/obervellach/6_obervellach_500x670.png",
            alt: "Rafting"
        },
        {
            url: "/img/trips/summer/obervellach/7_obervellach_500x670.png",
            alt: "Canyoning"
        },
        {
            url: "/img/trips/summer/obervellach/8_obervellach_500x375.png",
            alt: "Wandern"
        }
    ],
    map: {
        popupText: "Obervellach ...",
        latlng: [46.92650350720178, 13.201881895299174]
    }
}

export const flachau = {
    title: "Flachau",
    slogan: "Aciton pur - Langeweile unbekannt!",
    subslogan:"Du willst ganz besondere Ferien erleben? In Flachau erwartet dich eine der absoluten Top-Freizeiten "+
    "mit viel Action und Fun. Ob Spaß im Hochseilgarten, beim Schwimmen in einem der tollen Badeseen oder "+
    "beim Volleybalspielen – hier kommt keine Langeweile auf!",
    shortFacts: ["Wandern", "Alpen", "Österreich", "Natur", "Action"],
    facts: {
        area: {
            headline: "Vor Ort",

            icon: faMapMarkedAlt,
            list: ["Tennishalle mit zwei Plätzen (Teppichboden und Gummigranulat)", "Großraumsporthalle",
            "Fun-Court - Mehrzwecksportplatz mit Kunstrasen für alle möglichen Ballspiele", 
            "sechsteilige Trampolinanlage", "Volleyballplatz (Wiese)", "Grillhütte", "Lagerfeuerstelle"]
        },
        living: {
            headline: "Unsere Unterkunft",
            icon: faHome,
            text: "Schlafen werden wir in großen und wunderschönen 4 bis 6 Bettzimmern mit eigener Dusche & WC. "+
            "Natürlich gibt es Vollpension mit hervorragender Verpflegung (Frühstücksbuffet und 2-3 Gänge Mittag- "+
            "und Abendessen). Soft-Getränke stehen euch zu den Mahlzeiten kostenfrei zur Verfügung!"
        },
        program: {
            headline: "Programm",
            icon: faHiking,
            text:"Das Jugendsporthotel „Hanneshof“ in Flachau im schönen Ortsteil Filzmoos bietet uns jede Menge "+
            "Freizeitmöglichkeiten. In unserem eigenen Aufenthaltsraum gehts rund bei Gameshows, Disco und "+
            "Spieleabenden. In unmittelbarer Nähe befinden sich zusätzlich zwei schöne kleine Badeseen und ein "+
            "riesiges Schwimmbad. Eine der nebenstehenden Ausflugsmöglichkeiten ist bereits im Preis mit inbegriffen.",
            list: ["Liechtensteinklamm - eine der längsten begehbaren Wildwasserschluchten der Alpen",
                    "Stadt Salzburg", "Eisriesenwelt Werfen", "Sommerrodelbahn", "Burg Hohgenwerfen mit Falknerei", "Salzbergwerk Hallein mit Keltenmuseum",
                    "Hochseilklettergarten", "Wanderung mit Hüttenübernachtung am Tappenkarsee"]
        },
        evidence: "Unsere angebotenen Reisen sind standardmäßig für Personen mit eingeschränkter Mobilität nicht geeignet. Gerne möchten wir "+
        "aber Personen mit eingeschränkter Mobilität die Teilnahme ermöglichen. Bitte deine Eltern, bei uns im Büro anzurufen, so "+
        "dass wir das gemeinsam besprechen können.",
    },
    booking: {
        benefits: [
            {
                headline: "Termin",
                fact: "12.08. - 23.08.2022",
            },
            {
                headline: "Alter",
                fact: "12 bis 14 Jahre",
            },
            {
                headline: "Preis",
                fact: "655€",
            },
            {
                headline: "Teilnehmerzahl",
                fact: "mind. 35",
            }
        ],
        shortFacts:["Busreise ab/an Koblenz, Mainz, Raststätte Dannstadt", "Unterkunft in Mehrbettzimmern (4-6 Personen) mit eigener Dusche & WC",
            "Vollverpflegung (inkl. Getränke bei den Mahlzeiten)", "(Sport-)Programm & Ausflüge (wie beschrieben)"],
        link: "https://eveeno.com/223074723",
    },
    longText: "",
    gallery: [
        {
            url: "/img/trips/summer/flachau/1_flachau_500x375.png",
            alt: "Hochseilgarten"
        },
        {
            url: "/img/trips/summer/flachau/2_flachau_500x670.png",
            alt: "Spaß"
        },
        {
            url: "/img/trips/summer/flachau/3_flachau_500x670.png",
            alt: "Wandern"
        },
        {
            url: "/img/trips/summer/flachau/4_flachau_500x375.png",
            alt: "Sommerrodelbahn"
        },
        {
            url: "/img/trips/summer/flachau/5_flachau_500x375.png",
            alt: "See"
        },
        {
            url: "/img/trips/summer/flachau/6_flachau_500x670.png",
            alt: "Trampolin"
        },
        {
            url: "/img/trips/summer/flachau/7_flachau_500x670.png",
            alt: "Klettern"
        },
        {
            url: "/img/trips/summer/flachau/8_flachau_500x375.png",
            alt: "Unterkunft"
        } 
    ],
    map: {
        popupText: "Flachau ...",
        latlng: [47.363763622477705, 13.381823769519311]
    }
}


// ------------------------------------------------- //
// ------------- Winterfreizeiten ------------------ //
// ------------------------------------------------- //

export const blueprint = {
    title: "",
    slogan: "",
    shortFacts: [],
    shortInfo: [
        {
            headline: "Vor Ort",
            icon: faMapMarkedAlt,
            text: "",
            haus: []
        },
        {
            headline: "Ausflüge",
            icon: faHiking,
            text: [""]
        },
        {
            headline: "Unterkunft",
            icon: faHome,
            text: ""
        }
    ],
    benefits: [
        {
            headline: "Veranstallter",
            fact: "WirSindFerien des Skiverband Rheinhessen",
        },
        {
            headline: "Termine",
            fact: "",
        },
        {
            headline: "Alter",
            fact: "",
        },
        {
            headline: "Preis",
            fact: "",
        },
        {
            headline: "Teilnehmerzahl",
            fact: "",
        }
    ],
    hinweis: "",
    longText: "",
    gallery: ["", "", ""],
    map: {
        popupText: "",
        latlng: []
    }
}





// ------------------------------------------------- //
// -------------------- FAQ ------------------------ //
// ------------------------------------------------- //

export const faq = [
    {

        category: "Allgemein", 
        questionAnswer: [
            {
                question: "Corona",
                answer: "Aufgrund der sich ständig ändernden Rahmenbedingungen können " +
                "wir für den Sommer noch keine Aussage treffen, welche Regelungen in Bezug " +
                "auf Corona für die jeweilige Freizeit gelten werden. \n \n Wir halten uns "+
                "an die aktuellen Bestimmungen vor Ort im jeweiligen Reiseland. Detaillierte "+
                "Informationen werden wir in den Reiseunterlagen bzw. ggf. erst kurzfristig vor "+
                "der Reise zur Verfügung stellen können. Wir empfehlen dringend, eine Reiserücktrittsversicherung "+
                "abzuschließen."
            },
        {
                question:"WirSindFerien - unsere Werte",
                answer:"Auf unseren Freizeiten stehen Bewegung, Teamgeist, Action und Abenteuer im Vordergrund. "+
                "Keinen Platz hingegen haben bei uns Diskriminierung, Mobbing, Radikalismus, Gewalt, Drogen und "+
                "Fremdenfeindlichkeit. Hier setzen wir klare Grenzen, deren Nichteinhaltung zum sofortigen Reiseabbruch "+
                "führen kann."
            },
            {
                question:"Inklusion",
                answer:"Unsere Freizeiten sind offen für Alle – hast du körperliche oder geistige Einschränkungen, ist "+
                "dies erstmal kein Hinderungsgrund, an einer unserer Freizeiten teilzunehmen. Wir versuchen alles für "+
                "dich möglich zu machen, können aber leider noch nicht alles leisten. Bitte deine Eltern im Büro anzurufen, "+
                "so dass wir das gemeinsam besprechen können."
            }
        ]
    },
    {
        category: "Buchung",
        questionAnswer: [
            {
                question: "Was sind die Zahlungsmodalitäten?",
                answer: "Eine Anzahlung in Höhe von 20 % des Reisepreises ist innerhalb von 14 Tagen nach Erhalt der "+
                "schriftlichen Rechnung und des Sicherungsscheines zu zahlen. Der Restbetrag wird bis spätestens 4 Wochen "+
                "vor Abreise fällig, eingehend bei Nahegold - damit der rechtzeitige Versand der Reiseunterlagen gewährleistet "+
                "werden kann. \n \n Bei Buchungen ab 4 Wochen vor Reiseantritt sollte aus gleichem Grund umgehend nach Rechnungserhalt, "+
                "spätestens aber binnen 5 Werktagen ab dem Tag der Reisebuchung, der gesamte Reisepreis gezahlt werden. Entsprechende "+
                "Zahlungsfristen werden in der Rechnung aufgeführt."
            },
            {
                question: "Wie erfolt die Bezahlung der Reise?",
                answer: "Bezahlt wird ausschließlich per Banküberweisung nach Erhalt der Buchungsbestätigung/Rechnung. Der "+
                "Zahlungseingang wird nicht bestätigt."
            },
            {
                question: "Erhalten meine Eltern eine separate Rechnung für die Restzahlung?",
                answer: "Es gibt keine separate Rechnung für die Restzahlung, daher bitten wir dich und deine Eltern, "+
                "die Zahlungstermine selbst im Auge zu behalten."
            },
            {
                question: "Wie erfolgt die Zustimmung meiner Eltern zu der Reise?",
                answer: "Nach der Buchung bekommst du bzw. deine Eltern von uns eine Buchungsbestätigung sowie eine "+
                "Rechnung. Mit dem Eingang der Anzahlung auf unser Konto wird deine Buchung formal bestätigt. \n \n "+
                "Zusätzlich wird dir bzw. deinen Eltern ein Teilnehmerbogen zum Ausfüllen geschickt. Dieser ist "+
                "ausgefüllt bis 30 Tage vor Beginn der Reise per Post, Fax oder gescannt per E-Mail an uns zurückzusenden."
            },
            {
                question: "Gibt es eine Mindestteilnehmer:innenzahl und was passiert, wenn diese nicht erreicht wird?",
                answer: "Die Mindestteilnehmer:innenzahl liegt für den Großteil unserer Freizeiten bei 35 Personen "+
                "(pro Reise bzw. pro Reiseziel). In dem Fall, dass diese Zahl nicht erreicht wird, teilen wir dir und "+
                "deinen Eltern dies rechtzeitig mit. Ihr könnt euch dann entscheiden, ob ihr umbuchen oder kostenfrei "+
                "stornieren möchtet."
            },
            {
                question: "Wie ist das mit den Altersangaben für die Freizeiten?",
                answer: "Die Altersangaben sind eine gute Orientierungshilfe für dich. So kannst du sicher sein, dass "+
                "die anderen ungefähr in deinem Alter sind. Du hast Freunde oder Geschwister, die nicht in deinem Alter "+
                "sind, und ihr wollt eure Freizeit trotzdem gemeinsam verbringen? Dann meldet euch bei uns im Büro und wir "+
                "versuchen, eine gemeinsame Lösung zu finden."
            },
            {
                question: "Benötige ich eine Versicherung?",
                answer: "Wir empfehlen dir, eine Reiserücktrittskostenversicherung abzuschließen. \nWenn deine Reise ins "+
                "Ausland gehen soll, empfehlen wir dir zudem eine Auslandskrankenversicherung."
            },
            {
                question: "Werde ich mit meinen Freund:innen in einem Zimmer untergebracht sein?",
                answer: "Deinen Zimmerwunsch kannst du gerne bei der Buchung in den Kommentaren mit angeben, ist aber nicht zwingend notwendig. "+
                "Die Zimmervergabe erfolgt vor Ort mit allen Teilnehmer:innen zusammen, so dass du dann mit deinen Freunden ein gemeinsames "+
                "Zimmer belegen kannst. \n Bitte beachte: Auf unseren Freizeiten erfolgt die Unterbringung in gleichgeschlechtlicher Belegung "+
                "der Zimmer."
            }
        ]
    },
    {
        category: "Fahrt",
        questionAnswer: [
            {
                question: "An- und Abreise",
                answer: "Deine Ferienreise beginnt bereits beim Einstieg in unseren Reisebus. An deinem Abfahrtsort (Koblenz, "+
                "Mainz und Dannstadt) wirst du von einem:r Betreuer:in empfangen und du und deine Eltern haben die Möglichkeit, "+
                "offene Fragen direkt zu klären. Die Sicherheit steht für uns auf der Busreise im Vordergrund. \nDie Abfahrts- und "+
                "Ankunftszeiten erfährst du ca. 3-4 Wochen vor Abfahrt in einem separaten Anschreiben."
            },
            {
                question: "Wie erfahren meine Eltern von der Rückankunftszeit?",
                answer: "Die geplanten Rückankunftszeiten werden im Anschreiben, welches du ca. 4 Wochen vor der Freizeit erhältst, "+
                "bekannt gegeben. Wir geben dir rechtzeitig vor dem Eintreffen am jeweiligen Ankunftsort (Dannstadt, Mainz, Koblenz) "+
                "Bescheid, so dass du deine Eltern frühzeitig über die aktuelle Ankunftszeit informieren kannst."
            },
            {
                question: "Wie ist das mit der Verpflegung auf der Busfahrt?",
                answer: "Für die Hinfahrt im Bus musst du dir Verpflegung und Getränke selbst mitbringen. Wir empfehlen dies ausdrücklich, "+
                "da der Einkauf an den Raststätten erfahrungsgemäß recht teuer ist. Wir bitten darum, möglichst auf Glasflaschen zu verzichten "+
                "(Verletzungsgefahr!). \nAm Tag der Abreise haben wir (je nach Unterkunft) in der Regel die Möglichkeit, uns ein Lunchpaket für "+
                "die Heimreise zu packen. Sofern diese Verpflegungsleistungen nicht enthalten sind, empfehlen wir dir, vor Ort genügend Verpflegung "+
                "für die Rückfahrt einzukaufen."
            },
            {
                question: "Wie viele Pausen werden auf der Busfahrt gemacht?",
                answer: "Eine allgemeingültige Pausenregelung auf der Busfahrt gibt es nicht. Je nach Tages- und Nachtzeit werden alle 2-3 Stunden "+
                "Pausen eingelegt – nachts, wenn alle schlafen, spätestens jedoch nach 4,5 Stunden. Dies entspricht den gesetzlichen Regelungen. Das "+
                "Pausenende wird deutlich angesagt und muss zwingend eingehalten werden, da wir sonst den Zeitplan nicht einhalten können (Schichtzeiten der Fahrer!)."
            },
            {
                question: "Ausweis",
                answer: "Für alle Ferienfreizeiten benötigst du einen gültigen Kinder- bzw. Personalausweis oder Reisepass. Für viele Länder müssen "+
                "diese noch mindestens 3 Monate gültig sein. Bitte erkundige dich rechtzeitig vor Abreise über entsprechende Einreisebedingungen. Für "+
                "Teilnehmer:innen, die keine EU-Bürger:innen sind, gelten oftmals andere Einreisebedingungen – bitte informiere dich hier bei der "+
                "entsprechenden Botschaft."
            },
            {
                question: "Werde ich mit meinen Freund:innen in einem Zimmer untergebracht sein?",
                answer: "Deinen Zimmerwunsch kannst du gerne bei der Buchung in den Kommentaren mit angeben, ist aber nicht zwingend notwendig. "+
                "Die Zimmervergabe erfolgt vor Ort mit allen Teilnehmer:innen zusammen, so dass du dann mit deinen Freunden ein gemeinsames "+
                "Zimmer belegen kannst. \nBitte beachte: Auf unseren Freizeiten erfolgt die Unterbringung in gleichgeschlechtlicher Belegung "+
                "der Zimmer."
            },
            {
                question: "Was ist wenn in der Nacht etwas passiert?",
                answer: "Wir Betreuer:innen wohnen im gleichen Haus/Camp wie ihr und sind immer für euch erreichbar."
            }
        ]

    },
    {
        category: "Kontakt",
        questionAnswer: [
            {
                question: "Gibt es ein Notfalltelefon?",
                answer: "Mit dem Anschreiben erhalten deine Eltern alle Informationen rund um die Erreichbarkeit von unserem Büro. Eine "+
                "24-Stunden-Notrufnummer gibt es nicht. Wir stehen in ständigem Kontakt mit den Busfahrer:innen bzw. Betreuer:innen. Wir "+
                "bitten um Verständnis, dass die privaten Telefonnummern unserer Betreuer:innen nicht herausgegeben werden können."
            },
            {
                question: "Wie können mich meine Eltern während der Reise erreichen, wenn ich kein Handy habe?",
                answer: "Die meisten unserer Unterkünfte sind mit einem Telefon an der Rezeption ausgestattet. Auch auf den Campingplätzen "+
                "findet man häufig ein öffentliches Telefon an der Rezeption. Sprich diesbezüglich einfach deine Betreuer:innen an. Für "+
                "dringende Fälle stellt unser Büro auch den Kontakt über die Betreuer:innen zu dir her."
            }
        ]
    },
    {
        category: "Taschengeld",
        questionAnswer:[
            {
                question: "Wie ist das mit Taschengeld?",
                answer: "Eine allgemeingültige Aussage zum Taschengeld ist nicht möglich, da die Höhe vom Alter und dem individuellen Bedarf "+
                "abhängt. Erfahrungsgemäß sind 20 bis 50 Euro pro Woche ein guter Orientierungswert. Eine genauere Angabe findest du auch immer "+
                "im Anschreiben, das du ca. 4 Wochen vor Beginn der Freizeit erhältst. \n Da wir voll verpflegt werden, brauchst du ggf. nur etwas "+
                "Geld für Kleinigkeiten, wie z.B. Eis oder Softgetränke. \n Auf unseren Freizeiten kannst du dein Taschengeld bei den Betreuer:innen "+
                "zur Verwahrung und Verwaltung abgeben. Nach Bedarf kannst du dir dein Geld täglich zu festgelegten Zeiten in Teilbeträgen auszahlen lassen."
            },
            {
                question: "Wie kann ich meine Wertsachen vor Ort sicher aufbewahren?",
                answer: "Grundsätzlich übernehmen wir keine Haftung für Wertsachen und bitten um Verständnis, dass diese nicht von uns verwahrt "+
                "werden können. Lasst wertvolle Dinge, die ihr nicht unbedingt braucht, am besten zu Hause. Dein Taschengeld kannst du zur Aufbewahrung "+
                "an die Betreuer:innen vor Ort geben."
            }
        ]
    },
    {
        category: "Verpflegung",
        questionAnswer: [
            {
                question: "Krankheiten & Allergien",
                answer: "Solltest du an irgendwelchen Krankheiten oder Allergien leiden, ist es  wichtig, dass wir diese Informationen im Voraus "+
                "erhalten, um in Notfällen richtig und schnell reagieren zu können. So kann sich das Betreuer:innenteam bestens vorbereiten und "+
                "auch nochmal Kontakt mit dir aufnehmen. Auf dem Teilnehmer:innenbogen, den du vor der Freizeit zum Ausfüllen erhältst, kannst du "+
                "deine Daten alle eintragen. Gleiches gilt für regelmäßige Medikamenteneinnahmen. Um dich hiermit bestmöglich zu unterstützen, brauchen "+
                "wir von deinen Eltern eine genaue Dokumentation, welche Medikamente wie von dir eingenommen werden."
            },
            {
                question: "Wie sind die medizinischen Standards in den Reiseländern?",
                answer: "In unseren europäischen Reiseländern findest du Versorgungsstandards, die in etwa dem deutschen Standard entsprechen. Generell "+
                "empfehlen wir die Absicherung durch eine Auslandsreisekrankenversicherung. "
            },
            {
                question: "Wie werde ich im Krankheitsfall betreut?",
                answer: "Selbstverständlich stehen dir deine Betreuer:innen in Notfallsituationen mit Rat und Tat zur Seite. Für den Notfall sind alle "+
                "unsere Betreuer:innen geschult und wissen was zu tun ist, wenn auf der Freizeit doch mal was passiert. Sollte ein Transport erforderlich "+
                "sein, verfügen wir jederzeit über Notrufnummern, um einen Krankenwagen o.Ä. zu rufen. Deine Eltern werden umgehend informiert. "+
                "Selbstverständlich wirst du von unseren Betreuer:innen gut versorgt und gepflegt, bis du wieder fit bist."
            },
            {
                question: "Haben die Betreuer:innen eine Ersthelferausbildung?",
                answer: "Alle Betreuer:innen verfügen über eine Erste-Hilfe-Ausbildung, die regelmäßig aufgefrischt wird."
            }
        ]

    },
    {
        category: "Jugendschutz",
        questionAnswer: [
            {
                question: "Deine Betreuer:innen und deine Betreuung",
                answer: "Unsere Betreuer:innen planen für dich ein abwechslungsreiches, spaßiges, sportliches und interessantes Programm, um deine Freizeit "+
                "unvergesslich zu gestalten. Ein Freizeitteam setzt sich aus einem:r Freizeitleiter:in und mehreren Betreuer:innen zusammen. \n Wir legen Wert "+
                "auf eine qualifizierte Ausbildung deiner Betreuer:innen. Darum werden angehende Betreuer:innen zehn Tage auf ihren ersten Freizeiteinsatz "+
                "vorbereitet. Die Ausbildung schließt mit einer theoretischen und einer praktischen Prüfung ab, deren Bestehen die Voraussetzung für den Einsatz "+
                "auf einer Freizeit ist. Die Betreuer:innen wissen danach Bescheid, wenn es um den Umgang mit Kindern/Jugendlichen und die Planung und Durchführung "+
                "von Programmpunkten geht. Auch Rechtsfragen (Aufsichtspflicht, Jugendschutzgesetz etc.), Kommunikation und Motivation sind Thema der Ausbildung. "+
                "Bei Lehrgängen lernen sie Neues zu Themen wie Spiel & Spaß, Kommunikation oder Teamarbeit. Alle Betreuer:innen haben eine Erste-Hilfe-Ausbildung "+
                "absolviert, die regelmäßig aufgefrischt wird. \n Deine Betreuer:innen sind in der Regel zwischen 18 und 35 Jahre alt. Der Betreuer:innenschlüssel beträgt ca. "+
                "1:8; bei Teilnehmer:innen unter 12 Jahren 1:7. \n Unsere Betreuer:innen haben immer ein offenes Ohr für Fragen, Wünsche oder auch Probleme. Sie "+
                "versorgen dich auch vor deiner Freizeit mit ersten Informationen. Etwa vier Wochen vor dem Start deiner Ferienreise erhältst du von ihnen einen "+
                "Brief mit vielen nützlichen und wichtigen Informationen. \n Auf dem Gelände der Freizeitstätte darfst du dich selbstverständlich frei bewegen. "+
                "Abhängig von der Altersgruppe und den örtlichen Gegebenheiten darf das Gelände nach Abmeldung auch in Gruppen mit mind. drei Personen in der "+
                "freien Zeit verlassen werden."
            },
            {
                question: "Wie ist das mit dem Jugendschutzgesetz?",
                answer: "Auf allen Freizeiten gelten die Regeln des deutschen Jugendschutzgesetzes, d.h. Bier, Wein und Sekt dürfen ab 16 Jahren in Maßen konsumiert "+
                "werden. Gesetzlich ist das Rauchen erst ab 18 Jahren erlaubt. Auch Volljährige können an betreuten Jugendreisen teilnehmen: Du fällst zwar nicht "+
                "mehr unter das Jugendschutzgesetz, allerdings sind die allgemeinen Regeln auf der Freizeit natürlich auch für jeden Erwachsenen verbindlich! Daher "+
                "sind hochprozentiger Alkohol, Zigaretten, Verdampfer (e-Zigarette) und auch Wasserpfeifen auf allen Freizeiten nicht erlaubt. Gleiches Recht für Alle.\n "+
                "Diese Regelungen sind für uns auch im Ausland bindend. Jedoch kann es Ausnahmefälle geben, in denen die örtlichen Gegebenheiten eine abweichende bzw. "+
                "strengere und für uns bindende Vorschrift vorsehen (wie z.B. in Frankreich). \n Wir haften nicht für konfiszierte (unerlaubte) Ware bzw. Gegenstände "+
                "(dies gilt auch für Laserpointer)!"
            },
            {
                question: "Was sollte ich besser zu Hause lassen?",
                answer: "Du sollst jegliche Art der Waffen (Messer, Schlagringe, etc.) und Drogen zu Hause lassen. Hierbei verstehen wir auch keinen Spaß. Sollten "+
                "diese Dinge bei euch gefunden werden führt das zu einer sofortigen Heimreise auf eigene Kosten und die illegalen Gegenstände werden der Polizei übergeben."
            }
        ]
    }
]

// ------------------------------------------------- //
// -------------------- Footer --------------------- //
// ------------------------------------------------- //

export const footerText = 'Wir sind Ferien ist eine Organisation für Kinder- und Jugendfreizeiten. Bewegung, Teamgeist und '+
'Abenteuer stehen hierbei bei unseren Freizeiten und ehrenamtlichen Betreuer:innen im Vordergrund.'