import React, { useEffect } from 'react';

import { Navigation, Footer } from '../components/0_General/index.js';

function Datenschutz() {

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navigation isHome={false} />
      <section className="page-section bg-white">
        <div className="container px-4 px-lg-5">
          <h1>Datenschutzerklärung</h1>
          <hr className="divider divider-dark divider-left" />
          <p>wirsindferien freut sich über Ihren Besuch auf unserer Website. Wir nehmen den Schutz 
          Ihrer privaten Daten sehr ernst und möchten, dass Sie sich beim Besuch unserer Internet-Seite 
          wohl fühlen. Der Schutz Ihrer Privatsphäre bei der Verarbeitung persönlicher Daten ist für uns 
          ein wichtiges Anliegen, das wir bei unseren Geschäftsprozessen berücksichtigen. Wir verarbeiten Daten, 
          die beim Besuch unserer Seite erhoben werden, gemäß der DSGVO. Sollten darüber hinaus noch Fragen 
          zum Umgang mit Ihren personenbezogenen Daten offenbleiben, können Sie sich gerne an uns wenden.</p>
          <h3>1. Verantwortliche Stelle</h3>
          <p>Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten gem. 
            Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO) ist <br/><br/>
            Reisebüro Nahegold Omnibusbetrieb<br/>
            Inh. Jens Gemeinhardt e.K.<br/>
            Gewerbestraße 18<br/>
            55546 Pfaffen-Schwabenheim <br/>
            (im Folgenden "Nahegold").<br/><br/>
            Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten 
            Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu 
            beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr 
            erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
          </p>
          <h3>2. SSL/TLS-Verschlüsselung</h3>
          <p>Zur Gewährleistung der Sicherheit der Datenverarbeitung und zum Schutz der Übertragung vertraulicher 
            Inhalte nutzen wir eine SSL-bzw. TLS-Verschlüsselung. Sie erkennen das Vorhandensein einer verschlüsselten 
            Verbindung daran, dass in der Adresszeile des Browsers statt einem "http://" ein "https://" steht sowie 
            an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>
          <h3>3. Datenerfassung bei Besuch der Webseite/Speicherung von Logfiles</h3>
          <p>
          <b>a) Beschreibung und Umfang der Datenverarbeitung</b> <br/>
          Bei der rein informatorischen Nutzung unserer 
          Webseite erheben wir nur solche personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt (Server-Logfiles). 
          Mit jedem Aufruf unserer Internetseite werden eine Reihe von allgemeinen Daten und Informationen erfasst, die wir 
          in den Logfiles des Servers speichern. Konkret verarbeiten wir die nachfolgenden personenbezogenen Daten:
          </p>
          <ul>
            <li>Beim Zugriff auf die Webseite werden</li>
            <li>Browser/-version</li>
            <li>Verwendetes Betriebssystem</li>
            <li>IP-Adresse</li>
            <li>Datum und Uhrzeit der Serveranfrage</li>
          </ul>
          <p>
              
          <b>b) Rechtsgrundlage der Datenverarbeitung</b><br/>
          Rechtsgrundlage für die Speicherung der Daten/Logfiles ist 
          unser berechtigtes Interesse nach Art. 6 Abs. 1 f) DSGVO. <br/><br/>
          
          <b>c) Zweck der Datenverarbeitung</b><br/>
          Die Speicherung in Logfiles stellt ein ordnungsgemäßes Funktionieren 
          der Webseite sicher und dient darüber hinaus der Verbesserung und der Sicherheit unserer Systeme. Eine darüberhinausgehende 
          Auswertung dieser Daten (beispielsweise zu Marketingzwecken) findet in diesem Zusammenhang nicht statt.<br/><br/>
          
          <b>d) Dauer der Speicherung</b> <br/>
          Die gespeicherten Daten werden gelöscht, sobald sie für die Erreichung des Zweckes 
          ihrer Erhebung nicht mehr erforderlich sind. Dies ist nach spätestens sechs Wochen der Fall. Eine darüberhinausgehende 
          Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung 
          des aufrufenden Clients nicht mehr möglich ist.<br/><br/>
          
          <b>e) Widerspruchs- und Beseitigungsmöglichkeit</b> <br/>
          Die Erfassung der genannten Daten ist für den Betrieb der Webseite 
          zwingend erforderlich. Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
          </p>
          <h3>4. Cookies</h3>
          <p>Bei dieser Website handelt es sich um eine statische Seite und setzt aus diesem Grund keine Cookies.  </p>

          <h3>5. Weitergabe von Daten personenbezogenen Daten/Empfänger</h3>
          <p>Im Rahmen unserer Tätigkeit übermitteln wir personenbezogenen Daten nur dann an externe Stellen (z. B. Personen, Unternehmen 
            oder rechtlich selbstständige Organisationseinheiten, Kreditinstitute für die Zahlungsabwicklung), wenn dies im Rahmen der 
            Vertragsabwicklung notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende 
            Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben.
          </p>

          <h3>6. Beschreibungsmanagement</h3>
          <p><b>a) Beschreibung und Umfang der Datenverarbeitung</b><br/>
          Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit 
          sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten). 
          Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder 
          vorvertraglicher Maßnahmen gestattet. Im Falle einer Buchungsanfrage verarbeiten wir folgende personenbezogene Daten:<br/></p>
          <ul>
            <li>Bestandsdaten (z.B. Vor- und Nachname, Adresse)</li>
            <li>Kontaktdaten (z.B. E-Mail-Adresse, Telefonnummer)</li>
            <li>Anmeldedaten (z.B. Kommentare, Zustiegsort)</li>
          </ul>
          <p>

          <b>b) Rechtsgrundlage der Datenverarbeitung</b><br/>
          Ihre personenbezogenen Daten werden zur Vertragserfüllung und zur Durchführung (vor-) vertraglicher Maßnahmen nach 
          Art. 6 Abs. 1 b), Art. 88 Abs. 1 DS-GVO i. V. m. § 26 Abs. 1 BDSG) verarbeitet. Sofern Sie Ihre Einwilligung nach 
          Art. 6 Abs. 1 a) DS-GVO erteilen (z. B. für die Kontaktaufnahme per E-Mail) stellt dies die Rechtsgrundlage für die 
          Datenverarbeitung dar.<br/><br/>

          <b>c) Zweck der Datenverarbeitung</b><br/>
          Zweck der Verarbeitung ist die Durchführung einer ordnungsgemäßen Buchung bzw. eines Vertragsschlusses.<br/><br/>

          <b>d) Dauer der Speicherung</b><br/>
          Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung gelöscht. 
          Gesetzliche Aufbewahrungsfristen bleiben unberührt.<br/>
          </p>
          <h3>7. Kontakt/E-Mail</h3>
          <p>
          <b>a) Beschreibung und Umfang der Datenverarbeitung</b><br/>
          Eine Kontaktaufnahme ist über die bereitgestellte E-Mail-Adresse oder das Kontaktformular möglich. In diesem Fall 
          werden die mit der E-Mail/Nachricht übermittelten personenbezogenen Daten des Nutzers gespeichert.<br/>Es erfolgt 
          keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die Bearbeitung der Anfrage verwendet.<br/><br/>

          <b>b) Rechtsgrundlage der Datenverarbeitung</b><br/>
          Rechtsgrundlage für die Verarbeitung der Daten stellen gem. Art. 6 Abs. 1 f) DSGVO unsere berechtigten Interessen an 
          der Zurverfügungstellung einer Kontaktmöglichkeit sowie der Kommunikation mit Ihnen dar.<br/>Rechtsgrundlage für die 
          Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 f) DSGVO. 
          Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung 
          Art. 6 Abs. 1 b) DSGVO.<br/><br/>

          <b>c) Zweck der Datenverarbeitung</b><br/>
          Die Verarbeitung der personenbezogenen Daten dient allein der Bearbeitung der Kontaktaufnahme. Im Falle einer 
          Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten.<br/><br/>

          <b>d) Dauer der Speicherung</b><br/>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die 
          personenbezogenen Daten und diejenigen, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation 
          mit dem Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene 
          Sachverhalt abschließend geklärt ist.<br/><br/>

          <b>e) Widerspruchs- und Beseitigungsmöglichkeit</b><br/>
          Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der personenbezogenen Daten zu widerrufen. 
          Hierfür kann der Nutzer den Verantwortlichen über die auf der Webseite zur Verfügung gestellten Kontaktmöglichkeiten 
          kontaktieren. Nimmt der Nutzer per E-Mail Kontakt mit Nahegold auf, so kann er der Speicherung seiner personenbezogenen Daten 
          jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt werden.<br/>
          </p>
          <h3>8. Newsletter</h3>
          <p>
          <b>a) Beschreibung und Umfang der Datenverarbeitung</b><br/>
          Sie haben die Möglichkeit auf der Website unseren Newsletter zu abonnieren. Bei der Anmeldung zum Newsletter werden die aus 
          der Eingabemaske abgefragten Daten an Nahegold übermittelt. Zudem werden folgende Daten bei der Anmeldung erhoben:<br/>
          </p>
          <ul>
            <li>IP-Adresse des Rechners des Anmeldenden</li>
            <li>Datum und Uhrzeit der Registrierung</li>
            <li>Name der Website auf der Sie unseren Newsletter abonniert haben</li>
          </ul>
          <p>Nach erfolgter Abmeldung wird Ihre E-Mailadresse aus unserem Newsletter-Verteiler unverzüglich gelöscht. <br/><br/>
          
          <b>b) Rechtsgrundlage der Datenverarbeitung</b><br/>
          Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung zum Newsletter durch den Nutzer ist bei Vorliegen einer 
          Einwilligung des Nutzers Art. 6 Abs. 1 a) DSGVO. <br/>Im Falle der Zusendung eines Newsletters im Rahmen der zulässigen 
          Bestandskundenwerbung nach § 7 Abs. 3 UWG stellen unsere berechtigten Interessen nach Art. 6 Abs. 1 f DS-GVO unsere Rechtsgrundlage 
          dar. Das berechtigte Interesse entspricht den unten genannten Zwecken.<br/><br/>

          <b>c) Zweck der Datenverarbeitung</b><br/>
          Die Erhebung der E-Mail-Adresse des Nutzers dient dazu, den Newsletter zuzustellen.<br/>Die Erhebung sonstiger personenbezogener 
          Daten im Rahmen des Anmeldevorgangs dient dazu, einen Missbrauch der Dienste oder der verwendeten E-Mail-Adresse zu verhindern.<br/><br/>

          <b>d) Dauer der Speicherung</b><br/>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Die E-Mail-Adresse 
          des Nutzers wird demnach so lange gespeichert, wie das Abonnement des Newsletters aktiv ist.<br/><br/>

          <b>e) Widerspruchs- und Beseitigungsmöglichkeit</b><br/>
          Das Abonnement des Newsletters kann durch den betroffenen Nutzer jederzeit gekündigt werden. Zu diesem Zweck findet sich in jedem 
          Newsletter ein entsprechender Link. <br/>
          </p>
          <h3>9. Links zu externen Websites</h3>
          <p>Wir haben auf unserer Homepage Links zu externen Websites eingebunden. Wenn Sie auf den Link zu der externen Website klicken, 
            werden Sie auf die jeweilige Seite weitergeleitet. Wir haben keinen Einfluss auf die erhobenen Daten und 
            Datenverarbeitungsvorgänge dieser externen Seiten. Weitere Informationen zu Zweck und Umfang der Datenerhebung und 
            ihrer Verarbeitung durch den Anbieter erhalten Sie in den im Folgenden mitgeteilten Datenschutzerklärungen dieser 
            Anbieter. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten 
            zum Schutze Ihrer Privatsphäre. <br/> <br/>Adressen der heweiligen Plug-in-Anbieter und URL mit deren Datenschutzhinweisen: <br/><br/>
            </p>
            <p>
            Facebook: Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland; <br/> 
            <a href="https://www.facebook.com/about/privacy <br/><br/>"></a>
             </p>
            <p>
            Instagram: Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland;<br/>
           <a href= "https://help.instagram.com/519522125107875/?maybe_redirect_pol=0"></a>
            </p>
          <h3>10. Ihre Rechte</h3>
          <p>
          <b>a) Bestätigung</b><br/>
          Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden.<br/><br/>

          <b>b) Auskunft (Art. 15 DS-GVO)</b><br/>
          Sie haben das Recht, jederzeit von uns unentgeltliche Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten sowie 
          eine Kopie dieser Daten nach Maßgabe der gesetzlichen Bestimmungen zu erhalten.<br/><br/>

          <b>c) Berichtigung (Art. 16 DS-GVO)</b><br/>
          Sie haben das Recht, die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht Ihnen das Recht zu, 
          unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.<br/><br/>

          <b>d) Löschung (Art. 17 DS-GVO)</b><br/>
          Sie haben das Recht, von uns zu verlangen, dass personenbezogenen Daten, die sie betreffen, unverzüglich gelöscht werden, wenn einer der 
          gesetzlich vorgesehenen Gründe zutrifft und soweit die Verarbeitung bzw. Speicherung nicht erforderlich ist.<br/><br/>

          <b>e) Einschränkung der Verarbeitung (Art. 18 DS-GVO)</b><br/>
          Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der gesetzlichen Voraussetzungen gegeben ist.<br/><br/>

          <b>f) Datenübertragbarkeit (Art. 20 DS-GVO)</b><br/>
          Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und 
          maschinenlesbaren Format zu erhalten. Weiterhin haben Sie das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns, 
          dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß 
          Art. 6 Abs. 1 lit. a DS-GVO oder Art. 9 Abs. 2 lit. a DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 lit. b DS-GVO beruht und die 
          Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, 
          die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche uns übertragen wurde.<br/>Zudem haben Sie bei der 
          Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt 
          von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon 
          nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.<br/><br/>

          <b>g) Widerspruch (Art. 21 DS-GVO)</b><br/>
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender 
          personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit. e (Datenverarbeitung im öffentlichen Interesse) oder f (Datenverarbeitung 
          auf Grundlage einer Interessenabwägung) DS-GVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes 
          Profiling im Sinne von Art. 4 Nr. 4 DS-GVO.<br/>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, 
          es sei denn, wir können zwingende berechtigte Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, 
          oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Sie können jederzeit Widerspruch gegen 
          die Verarbeitung der personenbezogenen Daten zu Zwecken der Direktwerbung einlegen. Dies gilt auch für das Profiling, soweit es mit solcher 
          Direktwerbung in Verbindung steht. Widersprechen Sie gegenüber uns der Verarbeitung für Zwecke der Direktwerbung, so werden wir die 
          personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.<br/><br/>
          Zudem haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie betreffende Verarbeitung 
          personenbezogener Daten, die bei uns zu statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es 
          sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich. Ihnen steht 
          es frei, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, Ihr 
          Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.<br/><br/>

          <b>h) Beschwerde bei einer Aufsichtsbehörde</b><br/>
          Sie haben das Recht, sich bei einer für Datenschutz zuständigen Aufsichtsbehörde über unsere Verarbeitung personenbezogener Daten zu beschweren.
          <br/><br/>

          <b>i) Speicherdauer von personenbezogenen Daten</b><br/>
          Wir verarbeiten und speichern Ihre personenbezogenen Daten nur so lange, wie es der Speicherungszweck erfordert oder dies durch 
          Rechtsvorschriften bestimmt ist.<br/><br/>Entfällt der Speicherungszweck oder läuft eine vorgeschriebene Speicherfrist ab, 
          werden die personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht. Das 
          Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach 
          Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder 
          Vertragsanbahnung erforderlich sind.<br/>
          </p>
          <h3>11. Aktualität und Änderungen der Datenschutzerklärung</h3>
          <p>Diese Datenschutzerklärung ist aktuell gültig und hat den folgenden Stand: Januar 2022<br/><br/>Wenn wir unsere Internetseite 
          und unsere Angebote weiterentwickeln oder sich gesetzliche oder behördliche Vorgaben ändern, kann es notwendig sein, diese 
          Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung können Sie jederzeit auf dieser Webseite abrufen.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Datenschutz;