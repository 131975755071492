// import React
import React, { useEffect } from 'react';

// import Components
import { Navigation, Contact, Footer } from '../components/0_General/index.js';
import { Header, OurQuality, BookNow, PortfolioTrips } from '../components/1_Homepage/index.js';

// import Content
import { unsereWerte, homepageText, summerTrips, winterTrips } from '../assets/wirsindferien.js'

function Homepage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  
    return (
        <>
        <Navigation isHome={true}/>
        <Header />
        <BookNow homepageText={homepageText} />
        <PortfolioTrips summerTrips={summerTrips} winterTrips={winterTrips} />
        <OurQuality icons={unsereWerte} />
        <Contact />
        <Footer />
        </>
    );
}

export default Homepage;
