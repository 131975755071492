import React from 'react'

const Contact = () => {
    return (
        <section className="page-section bg-white">
            <div className="container px-4 px-lg-5">
                <div className="text-center">
                    <h2>Kontakt</h2>
                    <hr className="divider divider-dark" />
                    <div className="col-xl-4 m-auto">
                        <div className="card border-0 bg-light mt-xl-5">
                            <div className="card-body p-4 py-lg-5">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="text-center">
                                        <div className="h6 fw-bolder">Habt Ihr Fragen?</div>
                                        <p className="text-muted mb-4">
                                            Dann kontaktiert uns per Mail unter
                                            <br />
                                            <a href="mailto:info@nahegold.de">info@nahegold.de</a>
                                        </p>
                                        <p className="text-muted mb-4">
                                            oder direkt unter <br />
                                            Telefon: +49 6701 2020532 <br/>
                                            Telefax: +496701 2020945
                                        </p>
                                        <div className="h6 fw-bolder">Folge uns auf Social Media</div>
                                        <a className="fs-5 px-2 link-dark" href="https://www.facebook.com/wirsind.ferien.3"><i className="bi-facebook"></i></a>
                                        <a className="fs-5 px-2 link-dark" href="https://www.instagram.com/wirsindferien/"><i className="bi-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
