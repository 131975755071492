// Import React 
import React from 'react';

// Import CSS File
import './partner.css';

const Partner = (props) => {

    const partnerSection = props.partner.map((partner, index) => (
        <div className="container py-5" key={partner.title}>
            <div className="row gx-5 align-items-center">
                <div className={( (index % 2) !== 1 ? "col-lg-6 order-lg-2" : "col-lg-6")}>
                    <div className="p-5"><img className="img-fluid rounded-circle" src={partner.imageURL} alt={partner.title} /></div>
                </div>
                <div className="col-lg-6 order-lg-1">
                    <div className="px-5">
                        <h2>{partner.title}</h2>
                        <p className="text-justify">{partner.text}</p>
                        <a className="btn btn-secondary btn-xl" href={partner.link} target="_blank" rel="noreferrer" >{partner.buttonText}</a>
                    </div>
                </div>
            </div>
        </div>
    ));

    return (
        <>
            <section>
                <div className="partner-section">
                    <div className="container px-4 px-lg-5">
                        <div className="row gx-4 gx-lg-5">
                            <div className="col-md-10 col-lg-8 mx-auto text-center">
                                <h2 className="text-white mb-5">Partner</h2>
                                <p className="text-white-75 mb-5"></p>
                            </div>
                        </div>
                    </div>
                </div>
                {partnerSection}
            </section>
        </>
    );
}

export default Partner;