import React, { useEffect } from 'react';

import { Navigation, Footer } from '../components/0_General/index.js';
import { BrowserLook } from '../components/9_Errors/index.js';

function Error404() {

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  const error404 = {
    type: "Error 404",
    title: "Whoops!",
    text: "Page not found!",
  }

  return (
    <>
      <Navigation isHome={false} />
      <BrowserLook errorType={error404} />
      <Footer />
    </>
  );
}

export default Error404;