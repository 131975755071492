import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Program = (props) => {

    const list = props.program.list.map((element, index) => (
        <li className="mb-2" key={`benefit-${index}`}>
            {element}
        </li>
    ));


    return (
        <div className="row">
            <div className="col-12 col-md-6 px-3 py-2">
                <div className="row">
                    <div className="col-12 text-center-trip mb-2">
                        <i className="fs-1 float-start text-primary"><FontAwesomeIcon icon={props.program.icon} /></i>
                        <h4 style={{"position": "relative", "float":"left"}} className="m-0 py-2 ps-4">{props.program.headline}</h4>
                    </div>
                    <div className="col-12 text-center-trip">
                        <p className="text-justify">{props.program.text}</p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 px-3 py-2">
                <div className="row">
                    <div className="col-12 my-4 text-center-trip">
                    </div>
                    <div className="col-12 text-center-trip">
                        <ul className="mb-4">
                            {list}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Program
