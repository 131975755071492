import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AreaLiving = (props) => {
    // props areaLiving (array)

    const areaLiving = props.areaLiving.map((content, index) => {
        if (Object.keys(content).includes("text")) {
            return (
                <div className="col-12 col-md-6 px-3 py-2" key={index}>
                    <div className="row">
                        <div className="col-12 text-center-trip mb-2">
                            <i className="fs-1 float-start text-primary"><FontAwesomeIcon icon={content.icon} /></i>
                            <h4 style={{"position": "relative", "float":"left"}} className="m-0 py-2 ps-4">{content.headline}</h4>
                        </div>
                        <div className="col-12 text-center-trip">
                            <p className="text-justify">{content.text}</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            const list = content.list.map((element, index) => (
                <li className="mb-2" key={`benefit-${index}`}>
                    {element}
                </li>
            ));

            return (
                <div className="col-12 col-md-6 px-3 py-2" key={index}>
                    <div className="row">
                        <div className="col-12 text-center-trip mb-2">
                            <i className="fs-1 float-start text-primary"><FontAwesomeIcon icon={content.icon} /></i>
                            <h4 style={{"position": "relative", "float":"left"}} className="m-0 py-2 ps-4">{content.headline}</h4>
                        </div>
                        <div className="col-12 text-center-trip">
                            <ul className="mb-4">
                                {list}
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }
    });

    return areaLiving
}

export default AreaLiving
