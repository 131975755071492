import React, { useEffect } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet'

const UpdateMarker = (props) => {

    const map = useMap();

    useEffect(() => {
        map.flyTo(props.markerLatLng)
    }, [props]);

    return (
        <Marker position={props.markerLatLng}>
            {/* <Popup>
                {props.markerText}
            </Popup> */}
        </Marker>
    );
};

export default UpdateMarker;
