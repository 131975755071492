// Import React 
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

// Import CSS File
import './team.css';

const Team = (props) => {

    const services = props.icons.map((service, index) => (
        <div className="col-lg-3 col-md-6 col-6 text-center" key={index}>
            <div className="mt-5">
                <div className="mb-2"><i className="fs-1 text-primary"><FontAwesomeIcon icon={service.icon} /></i></div>
                <h3 className="h4 mb-2 text-hyphens">{service.title}</h3>
            </div>
        </div>
    ));

    const paragraphes = props.text.map((paragraph, index) => (
        <p className="text-dark-75 mb-4 mt-4 text-justify" key={index}>{paragraph.text}</p>
    ));

    return (
        <section className="page-section bg-white">
            <div className="container px-4 px-lg-5">
                <div className="text-center">
                    <h2 className="mt-0">{props.title}</h2>
                    <hr className="divider divider-dark" />
                    <div className="">
                        {paragraphes}
                    </div>
                    <div className="row gx-4 gx-lg-5">
                        {services}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team