// Import React 
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

// Import CSS Files
import './header.css';

// Import Content
import { slider } from '../../../assets/wirsindferien.js'

const Header = () => {

    const [slideNumber, setSlideNumber] = useState(1);
    const [imageSlides, setImageSlides] = useState(slider);

    function plusSlide(n) {
        var number = slideNumber + n;
        if (n === -1) {
            if (slideNumber === 1) {
                editSlide(3);
            } else {
                editSlide(number);
            }
        } else {
            if (slideNumber === 3) {
                editSlide(1);
            } else {
                editSlide(number);
            }
        }
    };

    function editSlide(id) {
        const editedSlides = imageSlides.map(slide => {
            if (id === slide.id) {
                return {...slide, isActive: true}
            } else {
                return {...slide, isActive: false}
            }
        });
        setImageSlides(editedSlides);
        setSlideNumber(id);
    };

    const imageSlideShow = imageSlides.map((imageSlide, index) => (
        <div className={(imageSlide.isActive ? "masthead active " + imageSlide.className : "masthead not-active " + imageSlide.className )} key={index} >
            <div className="container px-4 px-lg-5 h-100">
                <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                    <div className="col-lg-8 align-self-end">
                        <h1 className="text-white font-weight-bold">{imageSlide.headline}</h1>
                        <hr className="divider" />
                    </div>
                    <div className="col-lg-8 align-self-baseline">
                        <p className="text-white-75 mb-5">{imageSlide.text}</p>
                        <Link className="btn btn-primary btn-xl" to={imageSlide.link}>{imageSlide.buttonText}</Link>
                    </div>
                </div>
            </div>
        </div>
    ));

    const dots = imageSlides.map((imageSlide, index) => (
        <span key={index}
            className={(imageSlide.isActive ? 'dot active' : 'dot')}
            id={`dot-slide-${imageSlide.id}`} 
            onClick={() => editSlide(imageSlide.id)}>
        </span> 
    ));

    return(
        <header>
            <div className="slideshow-container">
                {imageSlideShow}
                <a className="prev" onClick={() => plusSlide(-1)}>&#10094;</a>
                <a className="next" onClick={() => plusSlide(1)}>&#10095;</a>

                <div className="dot-container">
                    {dots}
                </div>
            </div>
        </header>
    );
}
export default Header;