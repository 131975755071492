import React, { useEffect } from 'react';

import { Navigation, Footer } from '../components/0_General/index.js';

function Agb() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <>
      <Navigation isHome={false} />
      <section className="page-section bg-white">
        <div className="container px-4 px-lg-5">
            <h1>Allgemeine Geschäftsbedingungen</h1>
            <hr className="divider divider-dark divider-left" />
            <p>Aufgrund der besseren Lesbarkeit wird personenbezogenen Hauptwörtern und bei Personenbezeichnungen 
              im Folgenden die maskuline Form verwendet. Diese Begriffe gelten im Sinne der Gleichbehandlung 
              grundsätzlich für alle Geschlechter. Die verkürzte Sprachform beinhaltet somit keine Wertung.</p>

            <h3>1. Abschluss des Reisevertrags</h3>
            <p><b>1.1</b> Ihre Anmeldung kann schriftlich, mündlich, fernmündlich, online oder per E-Mail erfolgen. 
            Mündliche, fernmündliche oder E-Mail-Anmeldungen sowie Online-Anmeldungen (wenn aufgefordert) müssen 
            innerhalb von drei Tagen schriftlich nachgereicht werden. Diese Anmeldung ist ein Angebot Ihrerseits 
            zum Abschluss eines Reisevertrages. Der Reisevertrag kommt nach Zugang der schriftlichen Reisebestätigung 
            unsererseits und dem Eingang Ihrer Anzahlung auf den Reisepreis zustande. Die Anmeldung ist erst dann 
            verbindlich, wenn wir die Buchung und den Preis schriftlich bestätigt haben. Sie bedarf keiner 
            bestimmten Form.</p>
            
            <p><b>1.2</b> Zusagen und Nebenabreden von unserer Seite bedürfen immer der schriftlichen Bestätigung. 
            Das zulässige Alter der Teilnehmer ist aus den einzelnen Ausschreibungen zu ersehen und unbedingt 
            einzuhalten. Teilnehmer unter 18 Jahre bedürfen der Genehmigung eines Erziehungsberechtigten. Der 
            mitunterschreibende Erziehungsberechtigte übernimmt neben dem Anmelder die Verpflichtung, den 
            Rechnungsbetrag oder die Rücktrittskosten zu bezahlen, soweit eine solche Verpflichtung nicht 
            ausdrücklich ausgeschlossen ist. Die Anmeldung wird durch die Unterschrift und den Eingang bei 
            uns rechtsverbindlich.</p>

            <p><b>1.3.1</b> Mit der Buchung der Reise erkennt der Kunde die allgemeinen Geschäftsbedingungen 
            des Veranstalters an. Mit der Anmeldung wird anerkannt, dass die Teilnehmer den Weisungen und 
            Anordnungen des Betreuerteams folgen. Teilnehmer über 18 Jahre erkennen mit der Unterschrift bei 
            der Anmeldung an, dass sie sich den Anordnungen und Weisungen des Betreuerteams, unabhängig ihrer 
            Volljährigkeit, unterwerfen. Bei groben Verstößen gegen die Teilnahmebedingungen oder von den 
            Betreuern vorgegebenen Regeln können die Teilnehmer vorzeitig von der Freizeit ausgeschlossen 
            werden. Die Beurteilung des Regelverstoßes obliegt den Betreuern. Ein solches schwerwiegendes 
            Fehlverhalten liegt zum Beispiel dann vor, wenn der Teilnehmende sich selbst oder andere 
            Mitreisende gefährdet, Straftaten begeht, Drogen oder andere verbotene Substanzen konsumiert 
            oder vorsätzlich fremde Sachen (z. B. die Ausstattung der Unterbringung) beschädigt. Die 
            entstehenden Kosten für den Rücktransport gehen zu Lasten des Teilnehmers bzw. des 
            Erziehungsberechtigten. Bei Minderjährigen gehören dazu auch die Kosten für eine Begleitperson. 
            Ein Anspruch auf Erstattung des Reisepreises besteht in diesem Fall nicht.</p>

            <p><b>1.3.2</b> Mit der Anerkennung der Teilnahmebedingungen verpflichten sich die Teilnehmer bzw. 
            deren gesetzliche Vertreter, dem Veranstalter nach Zugang der Buchungsbestätigung über eventuelle 
            Krankheiten, Störungen, Medikamenteneinnahmen u. ä. zu unterrichten, damit ggf. hierauf, soweit 
            es im Rahmen der Freizeit möglich ist, Rücksicht genommen werden kann. Sollten jedoch dadurch 
            für uns unzumutbare Belastungen entstehen, behalten wir uns vor, die Buchungsbestätigung zu 
            widerrufen. Fehlende oder falsche Angaben können zum Widerruf des Vertrages sowie ggf. zu 
            Regressansprüchen unsererseits führen.</p>

            <h3>2. Bezahlung</h3>
            <p><b>2.1</b> Innerhalb von zwei Wochen nach Erhalt der Buchungsbestätigung wird eine Anzahlung 
            in Höhe von 20% des Reisepreises fällig und per Überweisung zahlbar. Die Höhe der Anzahlung 
            begründet sich in der besonderen Pauschalreiseform einer betreuten Jugendreise, die neben den 
            touristischen Leistungen die Betreuungsleistung einschließt. Die Betreuungsleistung bedingt 
            erhebliche zusätzliche Vorlaufkosten durch die qualifizierte Auswahl und Ausbildung des 
            Betreuungspersonals und berücksichtigt darüber hinaus die weiteren Vorlaufkosten. Der restliche 
            Reisepreis wird nur fällig, wenn feststeht, dass die Reise – wie gebucht – durchgeführt wird. 
            Die Restzahlung ist 4 Wochen vor Reisebeginn fällig.</p>

            <p><b>2.2</b> Bei Anmeldungen innerhalb 4 Wochen vor Fahrtantritt ist die gesamte Teilnahmegebühr 
            sofort fällig. Sollten wir den Eingang der Zahlungen nicht innerhalb von zwei Wochen nach 
            Zugang unserer Unterlagen auf unserem Konto verbuchen können, sind wir berechtigt, den Vertrag 
            fristlos zu kündigen, die Buchung zu stornieren und den Ferienplatz anderweitig zu vergeben. 
            Dies entbindet den Anmelder jedoch nicht von seiner Zahlungsverpflichtung. Es werden die aus 
            Ziffer 5 ersichtlichen Reiserücktrittskosten geltend gemacht.</p>

            <p><b>2.3</b> Leistet der Kunde die Anzahlung und/ oder die Restzahlung nicht entsprechend den 
            vereinbarten Zahlungsfälligkeiten, so ist der Reiseveranstalter berechtigt, nach Mahnung mit 
            Fristsetzung vom Reisevertrag zurückzutreten und den Kunden mit Rücktrittskosten gemäß 
            Ziffer 5.3 zu belasten.</p>

            <p><b>2.4</b> Der Sicherungsschein über unsere Insolvenzversicherung im Sinne des § 651 k BGB 
            ist im Preis enthalten. Der Sicherungsschein sichert die direkten Ansprüche des Teilnehmers 
            gegenüber dem Versicherungsunternehmen bei Zahlungs- und Leistungsunfähigkeit des Veranstalters. 
            Der Sicherungsschein wird mit der Buchungsbestätigung zugestellt.</p>
        
            <h3>3. Leistungsänderungen</h3>
            <p><b>3.1</b> Änderungen oder Abweichungen einzelner Reiseleistungen von dem vereinbarten Inhalt 
            des Reisevertrages (z.B. Änderungen des Programmablaufs), die nach Vertragsabschluss notwendig 
            werden und die vom Reiseveranstalter nicht wider Treu und Glauben herbeigeführt wurden, sind 
            nur gestattet, soweit Änderungen oder Abweichungen nicht erheblich sind und den Gesamtzuschnitt 
            der gebuchten Reise nicht beeinträchtigen.</p>

            <p><b>3.2</b> Eventuelle Gewährleistungsansprüche bleiben unberührt, soweit die geänderten 
            Leistungen mit Mängeln behaftet sind. </p>

            <p><b>3.3</b> Der Veranstalter verpflichtet sich, den Teilnehmer über Leistungsänderungen oder 
            -abweichungen unverzüglich auf einem dauerhaften Datenträger in Kenntnis zu setzen. 
            Gegebenenfalls wird er dem Kunden eine kostenlose Umbuchung oder einen kostenlosen Rücktritt anbieten.</p>

            <p><b>3.4</b> Falle einer erheblichen Änderung wird der Veranstalter die Änderungen dem Kunden mitteilen und eine 
            angemessene Frist zur Annahme setzen. Der Kunde ist berechtigt, innerhalb dieser Frist die Änderungen 
            anzunehmen oder unentgeltlich vom Reisevertrag zurückzutreten oder die Teilnahme an einer Ersatzreise, 
            sofern angeboten, zu verlangen. Reagiert der Kunde auf die mitgeteilte Änderung durch den 
            Reiseveranstalter nicht oder nicht innerhalb der gesetzten Frist, gilt die mitgeteilte Änderung 
            als angenommen. Hierauf ist der Kunde hinzuweisen.</p>

            <h3>4. Preisanpassungen</h3>
            <p><b>4.1</b> Der Veranstalter behält sich vor, den im Reisevertrag vereinbarten Preis bei Eintritt 
            bestimmter Umstände (z. B. Erhöhung/Senkung der Treibstoffkosten, Mehrwertsteuererhöhung, 
            Tarifänderungen etc.) oder einer Änderung der für die betreffende Reise geltenden Wechselkurse 
            entsprechend zu ändern.</p>

            <p><b>4.2</b> Eine Erhöhung ist nur zulässig, sofern die Unterrichtung des Kunden nicht später als 
            20 Tage vor Reisebeginn erfolgt und die zur Erhöhung führenden Umstände vor Vertragsschluss 
            noch nicht eingetreten und bei Vertragsschluss für den Reiseveranstalter nicht vorhersehbar waren.</p>

            <h3>5. Reiserücktirtt durch den Kunden, Umbuchungen</h3>
            <p><b>5.1</b> Der Kunde kann jederzeit vor Reisebeginn von der Reise zurücktreten. Maßgeblich für die Berechnung 
            möglicher Rücktrittskosten ist der Zugang der Rücktrittserklärung beim Reiseveranstalter. Dem Kunden wird 
            empfohlen, den Rücktritt schriftlich zu erklären.</p>

            <p><b>5.2</b> Tritt der Kunde vom Reisevertrag zurück oder tritt er die Reise nicht an, so verliert der 
            Reiseveranstalter seinen Anspruch auf den Reisepreis. Buchungen können bei Stornierung bis zum 45. 
            Tag vor Reisebeginn kostenfrei storniert werden. In allen anderen Fällen kann der Reiseveranstalter 
            statt des Reisepreises Ersatz für die getroffenen Reisevorkehrungen und für seine Aufwendungen 
            (Entschädigung) verlangen, soweit der Rücktritt nicht von ihm zu vertreten ist oder am Bestimmungsort 
            oder in dessen unmittelbarer Nähe keine unvermeidbaren, außergewöhnlichen Umstände auftreten, die die 
            Durchführung der Pauschalreise erheblich beeinträchtigen. Bei der Berechnung des Ersatzes sind 
            gewöhnlich ersparte Aufwendungen und gewöhnlich mögliche anderweitige Verwendungen der 
            Reiseleistungen zu berücksichtigen.</p>

            <p><b>5.3</b> Der Veranstalter ist berechtigt, eine Rücktrittspauschale geltend zu machen, soweit kein 
            Ersatzteilnehmer von dem stornierenden Teilnehmer gestellt wird. Diese werden pro Person in 
            Prozent des Reisepreises wie folgt berechnet und gelten ab Zustandekommen des Vertrages:<br/>
           </p>
            <ul>
               <li>Rücktritt bis 45 Tage vor Fahrtantritt: kostenlose Stornierung </li>
               <li>Rücktritt ab 44 Tage vor Fahrtantritt: 20% der Teilnahmegebühr/Preis</li>
               <li>Rücktritt ab 30 Tage vor Fahrtantritt: 50% der Teilnahmegebühr/Preis</li>
               <li>Rücktritt ab 15 Tagen vor Fahrtantritt: 80% der Teilnahmegebühr/Preis</li>
               <li>bei Nichtabmeldung bzw. Nichtantreten der Freizeit ist der volle Preis zu zahlen</li>
            </ul>
            <p>Bei höheren Forderungen unserer Leistungsträger gegenüber dem Veranstalter wie z. B. 
            volle Unterbringungskosten, werden diese unabhängig von den zuvor genannten Prozentsätzen, 
            an den Kunden weitergegeben und sind sofort nach Aufforderung zu zahlen. Spätere Anreise 
            bzw. frühere Abreise reduzieren den Teilnehmerbetrag nicht. Die entstehenden Kosten für 
            eine spätere Anreise bzw. eine frühere Abreise sind vom Teilnehmer selbst zu tragen. 
            Eventuell gezahlte Versicherungsgebühren oder anderweitige Kosten können nicht zurückerstattet 
            werden. Dem Reiseteilnehmer bleibt es unbenommen nachzuweisen, dass dem Veranstalter kein 
            oder ein geringerer Schaden entstanden ist als die geforderte Pauschale. Bei vorzeitigem 
            Beenden der Reise kann keine Teilrückerstattung erfolgen.</p>

             <p><b>5.4</b> Für Umbuchungen (z.B. des Reiseziels, des Reisetermins oder des Reiseteilnehmers) 
             kann eine Bearbeitungsgebühr von 10 Euro pro Person berechnet werden. Die Umbuchung 
             muss schriftlich bis zum 31. Tag vor Reisebeginn erfolgen.</p>

             <p><b>5.5</b> Bis 7 Tage vor Reisebeginn kann der Reisende verlangen, dass statt seiner 
             ein Dritter in die Rechte und Pflichten aus dem Reisevertrag eintritt. Der 
             Reiseveranstalter kann dem Eintritt des Dritten widersprechen, wenn dieser den 
             Reiseerfordernissen nicht genügt oder seiner Teilnahme gesetzliche Vorschriften oder 
             behördliche Anordnungen entgegenstehen. Für die Umbuchung werden die dem 
             Reiseveranstalter tatsächlich entstandenen Mehrkosten sowie die eventuell an 
             Leistungsträger (z.B. Fluggesellschaften, Fähren etc.) für die Umbuchung zu 
             zahlenden Mehrkosten berechnet. Auf Wunsch des Kunden erteilt der Reiseveranstalter 
             einen Nachweis über die Höhe der Mehrkosten. Tritt ein Dritter in den Vertrag ein, 
             so haften er und der Reisende dem Reiseveranstalter als Gesamtschuldner für den 
             Reisepreis und die durch den Eintritt des Dritten entstehenden Mehrkosten.</p>

            <h3>6. Rücktritt durch den Reiseveranstalter</h3>
             <p><b>6.1</b> Wir behalten uns das Recht vor, eine Freizeit bis 25 Tage vor Freizeitbeginn 
             abzusagen, wenn die ausgeschriebene und erforderliche Mindestteilnehmerzahl nicht 
             erreicht wird oder die Freizeit durch außergewöhnliche Umstände erheblich erschwert, 
             gefährdet oder beeinträchtigt wird. Die Mindestteilnehmerzahl ist in der jeweiligen 
             Reiseausschreibung aufgeführt.</p>

             <p><b>6.2</b> Der Veranstalter ist verpflichtet, dem Reiseteilnehmer die Absage unverzüglich 
             zu erklären, wenn feststeht, dass die Reise nicht durchgeführt wird. Den eingezahlten 
             Reisepreis erhalten die Teilnehmer dann in voller Höhe zurück.</p>

            <h3>7. Freizeitdurchführung</h3>
             <p><b>7.1</b> Die Buseinstiegsorte werden nur angefahren, wenn mindestens fünf Personen 
             für die jeweilige Freizeit dort zusteigen.</p>

             <p><b>7.2</b> Auf unseren Freizeiten gelten die Regelungen des deutschen 
             Jugendschutzgesetztes. Diese Regelungen sind für uns auch im Ausland bindend. Jedoch 
             kann es Ausnahmefälle geben, in denen die örtlichen Gegebenheiten eine abweichende 
             bzw. strengere und für uns bindende Vorschrift vorsehen.</p>

            <h3>8. Beschränkung der Haftung, Ansprüche und Verjährung</h3>
             <p><b>8.1</b> Wir haften im Rahmen der gesetzlichen Sorgfaltspflicht. Die vertragliche 
             Haftung der Veranstalter für Schäden, die nicht Körperschäden sind, ist auf den 
             dreifachen Reisepreis beschränkt, soweit ein Schaden nicht vorsätzlich oder grob 
             fahrlässig herbeigeführt wurde.</p>

             <p><b>8.2</b> Alle Ansprüche verjähren gemäß § 651 j BGB nach zwei Jahren beginnend 
             mit dem vertraglich vorgesehen Tag des Reiseendes.</p>

             <p><b>8.3</b> Der Teilnehmer ist verpflichtet, seine Beanstandungen unverzüglich dem 
             Freizeitleiter zur Kenntnis zu geben. Dieser ist beauftragt, für Abhilfe zu sorgen, 
             sofern dies möglich ist. Anzeigen gegenüber dem örtlichen Leistungsträger genügen 
             nicht. Der Freizeitleiter ist nicht berechtigt Ansprüche anzuerkennen. Unterlässt 
             es der Teilnehmer schuldhaft, einen Mangel anzuzeigen, so tritt ein Anspruch auf 
             Minderung nicht ein.</p>
          
            <h3>9. Pass-, Visa-, Devisen-, Zoll- und Gesundheitsbestimmungen</h3>
            <p><b>9.1</b> Der Teilnehmer ist für die Einhaltung der Pass-, Visa-, Devisen-, 
            Zoll- und Gesundheitsbestimmungen selbst verantwortlich.</p>

            <p><b>9.2</b> Alle Kosten und Nachteile, die aus der Nichtbeachtung dieser 
            Vorschriften erwachsen, gehen zu seinen Lasten, auch wenn diese Vorschriften 
            nach Buchung geändert werden sollten. Sofern dies dem Veranstalter möglich ist, 
            wird er den Teilnehmern über wichtige Änderungen der in der Freizeitausschreibung 
            wiedergegebenen allgemeinen Vorschriften vor Freizeitantritt informieren. 
            Ausländische Staatsbürger sollten sich rechtzeitig um entsprechende 
            Einreisebestimmungen bei den zuständigen Konsulaten erkundigen.</p>
          
            <h3>10. Versicherung</h3>
            <p>Wir empfehlen den Abschluss zusätzlicher Reiseversicherungen oder Reiseschutzpakete, 
            die Sie in jedem Reisebüro oder direkt bei Versicherungsunternehmen abschließen können. 
            Bei Auslandsfahrten ist es zusätzlich ratsam, eine entsprechende 
            Auslandsreisekrankenversicherung abzuschließen.</p>

            <h3>11. Sonstige Bestimmungen und Vereinbarungen</h3>
            <p><b>11.1</b> Mit der Anmeldung erklärt sich der Teilnehmer bzw. dessen gesetzlicher 
            Vertreter damit einverstanden, dass seine Daten entsprechend den gesetzlichen 
            Datenschutzbestimmungen der Datenschutz-Grundverordnung (EU-DSGVO) aufgenommen werden. 
            Es werden nur solche persönlichen Daten erhoben und an Partner weitergeleitet, die zur 
            Abwicklung der Reise notwendig und gefordert sind. Diese und unsere Mitarbeiter sind zur 
            Verschwiegenheit auf das Datengeheimnis verpflichtet.</p>

            <p><b>11.2</b> Mit der Veröffentlichung neuer Ausschreibungen (Prospekte etc.) oder 
            Preislisten verlieren alle früheren entsprechenden Veröffentlichungen über 
            gleichlautende Angebote und Termine ihre Gültigkeit.</p>

            <h3>12. Unwirksamkeit einzelner Bestimmungen</h3>
            <p>Die Unwirksamkeit einzelner Bestimmungen des Reisevertrages hat nicht die Unwirksamkeit 
            des ganzen Vertrages zur Folge.</p>

            <h3>13. Schlichtung</h3> 
            <p>Der Reiseveranstalter weist im Hinblick auf das Gesetz über 
            Verbraucherstreitbeilegung darauf hin, dass er nicht an einer freiwilligen 
            Verbraucherstreitbeilegung teilnimmt. Der Reiseveranstalter weist für alle Reiseverträge, 
            die im elektronischen Rechtsverkehr geschlossen wurden, auf die europäische <a href="http://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">Online-Streitbeilegungs-Plattform</a> hin.</p>
          
            <h3>14. Gerichtsstand</h3>
            <p>Es gilt deutsches Recht. Gerichtsstand für Vollkaufleute, juristische Personen des 
            öffentlichen oder privaten Rechts und für Personen, die keinen allgemeinen 
            Gerichtsstand im Inland haben sowie für Personen, die nach Abschluss des Vertrages 
            ihren Wohnsitz oder gewöhnlichen Aufenthalt ins Ausland verlegt haben, oder deren Wohnsitz 
            oder gewöhnlicher Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt ist, sowie 
            für Passivprozesse, ist der Sitz des Reiseveranstalters. Beides gilt nur dann nicht, 
            wenn internationale Übereinkommen zwingend etwas anderes vorschreiben.</p>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Agb;