import React, { useEffect } from 'react';

import { Navigation, Contact, Footer } from '../components/0_General/index.js';

function Kontakt() {

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navigation isHome={false} />
      <h1>Kontakt</h1>
      <Contact />
      <Footer />
    </>
  );
}

export default Kontakt;