// Import React
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

// Import CSS Files
import './App.css';

import {Homepage, Freizeit, Freizeiten, UeberUns, Kontakt, Error404, Impressum, Datenschutz, Faq, Agb } from './pages/index.js';
import { schubybeach, flachau, heino, obervellach } from './assets/wirsindferien.js';
import { ErrorBoundary } from './components/9_Errors/index.js';

function App() {

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
            <Route exact path="/freizeiten/schubybeach" element={<Freizeit trip={schubybeach} />} />
            <Route exact path="/freizeiten/flachau" element={<Freizeit trip={flachau} />} />
            <Route exact path="/freizeiten/heino" element={<Freizeit  trip={heino} />} />
            <Route exact path="/freizeiten/obervellach" element={<Freizeit trip={obervellach} />} />
            <Route exact path="/freizeiten" element={<Freizeiten />} />
            <Route exact path="/ueber-uns" element={<UeberUns />} />
            <Route exact path="/kontakt" element={<Kontakt />} />
            <Route exact path="/impressum" element={<Impressum />} />
            <Route exact path="/datenschutz" element={<Datenschutz />} />
            <Route exact path="/agb" element={<Agb />} />
            <Route exact path="/faq" element={<Faq />} />
            <Route index path="/" element={<Homepage />} />
            <Route path="/*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
