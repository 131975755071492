// Import React
import React from 'react';
import {Link} from 'react-router-dom';

// Import CSS Files
import './footer.css';

import { footerText, summerTrips, winterTrips } from '../../../assets/wirsindferien.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    
    const summerTripsList = summerTrips.map((trip, index) => (
            <li key={index}><Link className="link-footer link-color" to={trip.link}>{trip.title}, {trip.country}</Link></li>
        ));

    const winterTripsList = winterTrips.map((trip, index) => (
            <li key={index}><Link className="link-footer link-color" to={trip.link}>{trip.title}, {trip.country}</Link></li>
        ));

    return(
        <footer>
            <div className="footer-container bg-primary">
                <div className="container px-4 px-lg-5">
                    <div className="footer-row">
                        <div className="footer-column left">
                            <h4 className="text-white mb-2">Über WirSindFerien</h4>
                            <p className="footer text-white mb-0">{footerText}</p>
                            {/* <p className="footer text-white mb-0">Werde Teil unseres <Link className="link-footer link-color" to="/betreuer-werden">Betreuer-Teams!</Link></p> */}
                            <div className="footer-socials">
                                <span className="text-white social-label">Folge uns:</span>
                                <div className="social-links">
                                    <a href="https://www.facebook.com/wirsindferien/" target="_blank" rel="noreferrer" title="Facebook"><i className="fs-1 social-icon"><FontAwesomeIcon icon={faFacebookSquare} /></i></a>
                                    <a href="https://www.instagram.com/wirsindferien/" target="_blank" rel="noreferrer" title="Instagram"><i className="fs-1 social-icon"><FontAwesomeIcon icon={faInstagramSquare} /></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="footer-column middle">
                            <h5 className="text-white mb-2">Sommerfreizeiten</h5>
                            <ul className="footer text-white">
                                {(summerTrips.length === 0) ? 
                                <li>Zur Zeit haben wir leider noch keine Sommerfreizeiten geplant!</li> :
                                summerTripsList}
                            </ul>
                        </div>
                        <div className="footer-column py-3 py-lg-0 right">
                            <h5 className="text-white mb-2">Winterfreizeiten</h5>
                            <ul className="footer text-white">
                                {(winterTrips.length === 0) ? 
                                <li>Winterfreizeiten sind aktuell in der Planung und können demnächst gebucht werden!</li> :
                                winterTripsList}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-light py-5">
                <div className="container px-4 px-lg-5 ">
                    <div className="small text-left text-muted">Copyright &copy; 2022 - WirSindFerien.de | All Rights Reserved.</div>
                    <div className="footer small text-right text-muted">
                        <Link className="link-footer" to="/impressum"> Impressum</Link> | 
                        <Link className="link-footer" to="/datenschutz"> Datenschutz</Link> | 
                        <Link className="link-footer" to="/agb"> AGB</Link> | 
                        <Link className="link-footer" to="/faq"> FAQ</Link> |
                        <Link className="link-footer" to="/kontakt"> Kontakt</Link>
                    </div>
                    <div style={{"clear": "both"}}></div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;