// Import React 
import React from 'react'

// Import CSS File
import './Facts.css'

// Import React Components
import FactSection from './FactSection/FactSection'
import BookingCard from './BookingCard/BookingCard'

const ShortFacts = (props) => {


    return (
        <section className="page-section bg-white">
            <div className="container px-4 px-lg-5">
                <div className="text-center">
                    <h2 className="mt-0">{props.title}</h2>
                    <hr className="divider divider-dark" />
                </div>
                <div className="container-fluid py-4">
                    <div className="row g-0">
                        <div className="col-12 col-lg-8 px-4">
                            <FactSection
                                title={props.title}
                                slogan={props.slogan}
                                shortFacts={props.shortFacts}
                                subslogan={props.subslogan}
                                facts={props.facts}
                            />
                        </div>
                        <div className="col-12 col-lg-4 mx-auto">
                            <BookingCard 
                                title={props.title} 
                                benefits={props.booking.benefits} 
                                shortFacts={props.booking.shortFacts}
                                link={props.booking.link}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ShortFacts