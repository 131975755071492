import React, { useEffect } from 'react';

import { Navigation, Footer } from '../components/0_General/index.js';
import { Team, Partner } from '../components/4_AboutUs/index.js';

// Import content
import { nahegold, skiverband, unsereWerte, textUeberUns } from '../assets/wirsindferien.js'

function UeberUns() {

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Navigation isHome={false} />
      <Team title="Über uns" text={textUeberUns} icons={unsereWerte}/>
      <Partner partner={[nahegold, skiverband]}/>
      <Footer />
    </>
  );
}

export default UeberUns;