import React from 'react'

import './BookingCard.css'

const BookingCard = (props) => {

    const benefits = props.benefits.map((benefit, index) => (
        <li className="mb-2" key={`benefit-${index}`}>
            <strong><b>{benefit.headline}</b>: {benefit.fact}</strong>
        </li>
    ));

    const shortFacts = props.shortFacts.map((info, index) => (
        <li className="mb-2" key={`benefit-${index}`}>
            {info}
        </li>
    ))

    return (
        <div className="card mt-3 mx-2 mb-xl-0 sticky">
            <div className="card-body p-4">
                <div className="small text-uppercase fw-bold text-muted">{props.title}</div>
                <div className="mb-3">
                    Facts & Leistungen
                </div>
                <ul className="list-unstyled mb-4">
                    {benefits}
                </ul>
                <hr className="divider divider-dark divider-left" />
                <ul className="mb-4">
                    {shortFacts}
                </ul>
                {/* <div className="d-grid">
                    <a className="btn btn-outline-primary" href={props.link} target="_blank" rel="noreferrer">Freizeit buchen!</a>
                </div> */}
            </div>
        </div>
    )
}

export default BookingCard