// Import React 
import React from 'react';

// Import CSS File
import './description.css';

const Description = (props) => {
    return (
        <section className="py-0">
            <div className="trip-section">
                <div className="container px-4 py-4 px-lg-5">
                    <div className="row gx-4 gx-lg-5">
                        <div className="col-md-10 col-lg-8 mx-auto text-center">
                            <div className="mb-2">
                                <span className="dot-logo">
                                    <img className="logo" src="/img/logo/WirSindFerien/WSF_BW_OR_OT_white.png" alt="wirsindferien_logo" />
                                </span> 
                            </div>
                            <h2 className="text-white mb-5">Impressionen: {props.title}</h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container p-4 px-lg-5">
                <div className="text-center">
                    <h3>{props.title}</h3>
                    <p className="text-dark-75 mb-4 mt-4">{props.text}</p>
                </div>
            </div> */}
        </section>
    )
}

export default Description
