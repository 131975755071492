import React from 'react'

import './faqContact.css'

const FaqContact = () => {
    return (
        <div className="col-xl-4">
            <div className="card border-0 bg-light mt-xl-5 sticky">
                <div className="card-body p-4 py-lg-5">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="text-center">
                            <div className="h6 fw-bolder">Habt Ihr weitere Fragen?</div>
                            <p className="text-muted mb-4">
                                Dann kontaktiert uns unter
                                <br /> 
                                <a href="mailto:info@nahegold.de">info@nahegold.de</a>
                            </p>
                            <div className="h6 fw-bolder">Folge uns auf Social Media</div>
                            <a className="fs-5 px-2 link-dark" href="https://www.facebook.com/wirsindferien/"><i className="bi-facebook"></i></a>
                            <a className="fs-5 px-2 link-dark" href="https://www.instagram.com/wirsindferien/"><i className="bi-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqContact
