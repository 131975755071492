// Import React
import React from 'react';

import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import { faSync } from '@fortawesome/free-solid-svg-icons';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    this.setState({
      error: error,
    })
  }

  render() {
    if (this.state.error) {
      return (
        <div className='error-message'>
          <h2>Hoppla! Etwas ist schiefgelaufen. Versuchen Sie, die Seite neu zu laden.</h2>
          <Link onClick={() => window.location.reload(false)}>
            <i className="fs-1 text-primary"><FontAwesomeIcon icon={faSync} /></i>
          </Link>
        </div>
      );
    }
    return this.props.children;
  }
}